/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";

const CreateBot = () => {
  return (
    <>
      <section className="d_flx create_bot">
        <div className="create_lhs">
          <figure>
            <img src="images/tunix.svg" alt="bot" />
          </figure>
          <h2>Tunix</h2>
          <p>
            Tunix's AI-powered bot builder to easily create bots and
            conversational workflows.
          </p>
          <Link to="/bot_template">CREATE NEW BOT</Link>
        </div>
        <div className="create_rhs clr_white">
          <h2>Tunix</h2>
          <ul>
            <li>Flow Diagram with NLP Capabilities</li>
            <li>Simple and intuitive interface</li>
            <li>Add interactive buttons, Image etc.</li>
          </ul>
          <figure className="data_flow">
            <img src="images/data.png" alt="bot" />
          </figure>
        </div>
      </section>
    </>
  );
};
export default CreateBot;
