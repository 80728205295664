import { isEmail, validMobileNo } from "../utils/validations";

const UserInput = ({
  placeholder,
  icon,
  value,
  onChange,
  type,
  require,
  disabled,
  onKeyDown,
}) => {
  const showError = (errorMessage) => {
    return <p style={{ color: "red", fontSize: 15 }}>{errorMessage}</p>;
  };

  const validateInput = () => {
    if (require) {
      if (!value) {
        return showError(`Please enter ${placeholder}`);
      } else if (placeholder === "Email" && !isEmail(value)) {
        return showError(`Please enter valid ${placeholder}`);
      } else if (placeholder === "Phone Number" && !validMobileNo(value)) {
        return showError(`Please enter valid ${placeholder}`);
      }
    }
    if (value && !validMobileNo(value) && placeholder === "Phone Number") {
      return showError("Enter valid phone number");
    }
    return null;
  };

  return (
    <div className="input_box m_0">
      <figure className="sms">
        <img src={icon} alt="customer"></img>
      </figure>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
      {validateInput()}
    </div>
  );
};

export default UserInput;
