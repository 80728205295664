import SearchBox from "../../../components/search";
import Filter from "../../../components/filter";
import PaginationBox from "../../../components/pagination";
import React, { useCallback, useEffect, useState } from "react";
import {
  useFilterRatingMutation,
  useRatingByCustMutation,
} from "../../../services/ratingbycustomer";
import { Empty, Rate, Skeleton } from "antd";

const RatingList = () => {
  const [ratingByCust] = useRatingByCustMutation();
  const [filterRating] = useFilterRatingMutation();
  const [agentData, setAgentData] = useState([]);
  const [load, setLoad] = useState(false);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [rate, setRate] = useState([]);
  const [totalCnt, setTotalCnt] = useState();
  const [current, setCurrent] = useState(1);

  const onChange = (page) => {
    setCurrent(page);
  };

  const fetchData = useCallback(async () => {
    if (!from && !to && !rate) {
      return;
    }
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
      };
      const response = await ratingByCust(body);
      console.log("response: ", response);
      setAgentData(response?.data?.data);
      setTotalCnt(response?.data.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [from, to, rate, current, ratingByCust]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filter = async () => {
    let response;
    try {
      setLoad(true);
      let body = {
        ratings_selection: rate,
        start_date: from,
        end_date: to,
      };
      response = await filterRating(body);
      setAgentData(response?.data?.data);
      console.log("response: ", response);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  const handleRate = (val, check) => {
    setRate((prevVal) => {
      if (check) {
        if (!prevVal.includes(val)) {
          const newRating = [...prevVal, val];
          newRating.sort((a, b) => a - b);
          return newRating;
        }
      } else {
        const newRating = prevVal.filter((rating) => rating !== val);
        return newRating;
      }
      return prevVal;
    });
  };

  const handleFromChange = (e) => {
    setFrom(e);
  };

  const handleToChange = (e) => {
    setTo(e);
  };

  const handleSearch = async (e) => {
    const search = e.target.value;
    console.log("search: ", search);
    if (!search) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        search_value: search ?? "",
      };
      const response = await ratingByCust(body);
      console.log("response: ", response);
      setAgentData(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <div className="bg_bx">
        <div className="agent_head d_flx">
          <h2>Ratings</h2>
          <div className="flt_search">
            <SearchBox onChange={handleSearch} />
            <Filter
              onFromChange={handleFromChange}
              onToChange={handleToChange}
              onClick={filter}
              ratings={handleRate}
            />
          </div>
        </div>
        <div className="table_box">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>User Name</th>
                <th>Reviews</th>
              </tr>
            </thead>
            <Skeleton loading={load} active block={true}>
              <tbody>
                {agentData?.length ? (
                  agentData?.map((agent) => (
                    <tr key={agent?.id}>
                      <td>{agent?.user_fname}</td>
                      <td>{agent?.rating_by}</td>
                      <td>{agent?.username}</td>
                      <td>
                        <div className="rating_star">
                          <Rate
                            disabled
                            defaultValue={agent?.ratings}
                            style={{ color: "#414de1" }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : !load ? (
                  <div className="no_dt">
                    <Empty description="No rating is present." />
                  </div>
                ) : null}
              </tbody>
            </Skeleton>
          </table>
        </div>
        {agentData?.length && totalCnt > 10 ? (
          <PaginationBox
            defaultCurrent={1}
            current={current}
            onChange={onChange}
            total={totalCnt}
          />
        ) : null}
      </div>
    </>
  );
};
export default RatingList;
