/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <>
      <div className="add_yrbot d_flx">
        <div className="yr_lhs">
          <h1>Add your Bot </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nec
            egestas justo, non venenatis nunc. Vestibulum consequat viverra
            augue, at posuere dui ultricies ultrices. Integer consequat aliquet
            sem sit amet venenatis.
          </p>
          {/* <Link to="/dashboard">Create your Bot</Link> */}
          <Link to="/botintegration">Create your Bot</Link>
        </div>
        <div className="yr_rhs">
          <figure>
            <img src="./images/bot.png" alt="bot" />
          </figure>
        </div>
      </div>
    </>
  );
};
export default Banner;
