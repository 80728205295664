import React, { useState } from "react";
import { Dropdown, Modal } from "antd";
import { STORAGE_KEYS } from "../constants/storage";
import { getData } from "../constants";
import {
  useAddMessageMutation,
  useDeleteOptionMutation,
  useUpdateOptMutation,
} from "../services/botBuilder";
import { errorToast, successToast } from "../helpers";
import { Link } from "react-router-dom";

const UserMessage = ({ open, setOpen, nodeId, nodeName }) => {
  const [addMessage, setAddMessage] = useState("");
  const [editMessage, setEditMessage] = useState("");
  const [addOption] = useAddMessageMutation();
  const [deleteOpt] = useDeleteOptionMutation();
  const [updateOpt] = useUpdateOptMutation();
  const [addRequire, setAddRequire] = useState(false);
  const [editRequire, setEditRequire] = useState(false);

  const hide = () => {
    setOpen(false);
    if (addRequire) {
      setAddRequire(false);
    }
    if (editRequire) {
      setEditRequire(false);
    }
    if (addMessage) {
      setAddMessage("");
    }
    if (editMessage) {
      setEditMessage("");
    }
  };

  const handleAddOption = async () => {
    if (!addMessage) {
      setAddRequire(true);
      return;
    }
    try {
      const body = {
        name: addMessage,
        parent: nodeId,
      };
      const response = await addOption({
        body,
        prod_bot_id: getData(STORAGE_KEYS?.prod_bot_id),
      });
      console.log("response: ", response);
      if (response?.data?.code === 201) {
        successToast(response?.data?.message);
        setOpen(false);
      } else {
        errorToast(response?.data?.message);
      }
    } catch (error) {
      console.log("error adding option: ", error);
    } finally {
      hide();
    }
  };

  const handleEditOption = async () => {
    if (!editMessage) {
      setEditRequire(true);
      return;
    }
    try {
      const body = {
        name: editMessage,
        parent: nodeId,
      };
      if (nodeId) {
        const response = await updateOpt({ body, id: nodeId });
        console.log("response: ", response);
        if (response?.data?.code === 200) {
          successToast(response?.data?.message);
          setOpen(false);
        } else {
          errorToast(response?.data?.message);
        }
      } else {
        errorToast("Parent name cannot be change");
      }
    } catch (error) {
      console.log("error editing option: ", error);
    } finally {
      hide();
    }
  };

  const items = [
    {
      label: (
        <ul
          role="menu"
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="button-types-dropdown"
        >
          <li class="dropdown-header">Add Button</li>
          <li>
            <a>
              <figure>
                <img src="../images/bk_arrow.svg" alt="dots" />
              </figure>
              <span class="gBXVrn">
                {" "}
                Default Button{" "}
              </span>
              <span class="bTkyvh">
                {" "}
                Suggested Replies{" "}
              </span>
            </a>
          </li>
          <li>
            <a>
            <figure>
                <img src="../images/prefix.svg" alt="dots" />
              </figure>
              <span class="gBXVrn">
                {" "}
                Link Button{" "}
              </span>
              <span class="bTkyvh">
                {" "}
                Add links to button{" "}
              </span>
            </a>
          </li>
          <li>
            <a>
            <figure>
                <img src="../images/sb_btn.svg" alt="dots" />
              </figure>
              <span class="gBXVrn">
                {" "}
                Submit Button{" "}
              </span>
              <span class="bTkyvh">
                {" "}
                Send data on button press{" "}
              </span>
            </a>
          </li>
        </ul>
      ),
      key: "0",
    },
  ];

  const deleteOption = async () => {
    try {
      if (nodeId) {
        const response = await deleteOpt({ id: nodeId });
        console.log("response: ", response);
      } else {
        errorToast("Parent name cannot be delete");
      }
    } catch (error) {
      console.log("error deleting option: ", error);
    } finally {
      hide();
    }
  };

  const handleAddKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddOption();
    }
  };

  const handleEditKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleEditOption();
    }
  };

  return (
    <Modal
      open={open}
      onCancel={hide}
      width={490}
      footer={null}
      className="rgt_modal"
    >
      <div className="edt_msg">
        <div className="scrl_bx">
          <div className="edt_cnt">
            <h2>
              <figure>
                <img src="../images/bot.png" alt="bot"></img>
              </figure>{" "}
              User Message
            </h2>
            <p>Ai Chatbot uses AI capabilities to match the training phrase to the user message. The more phrases you add, the better the bot will get at answering user’s questions.</p>
          </div>
          <div className="nm_input">
            <input type="text" placeholder="Enter a name for this message"/>
          </div>
          <h3>User says</h3>
          <div className="add_trg_box">
              <button type="button">+ Add Training Phrase</button>
              <input type="text" placeholder="Enter the user's message"></input>
            </div>
        </div>
        <div className="sv_cncl_btn">
          <button type="button" className="cncl_btn" onClick={hide}>
            Cancel
          </button>
          <button type="button" className="sv_btn">
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UserMessage;
