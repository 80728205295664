// authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    stepperData: [],
};

const feature = createSlice({
    name: 'feature',
    initialState,
    reducers: {
        setStepperData: (state, action) => {
            state.stepperData.push(action.payload.stepperData);
        },
        removeStepperData: (state, action) => {
            const indexToRemove = state.stepperData.findIndex(item => item === action.payload.stepperData);
            if (indexToRemove !== -1) {
                state.stepperData.splice(indexToRemove, 1);
            }
        },
        reset: () => {
            return initialState;
        }
    },
});

export const { setStepperData, reset, removeStepperData } = feature.actions;

export default feature.reducer;
