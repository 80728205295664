/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import RatingList from "./features/ratinglist";

const Rating = () => {
  return (
    <>
      <Layout>
        <section className="main_area">
            <RatingList />
        </section>
      </Layout>
    </>
  );
};
export default Rating;
