/* eslint-disable jsx-a11y/img-redundant-alt */
import InputBox from "../../../components/inputbox";
import SaveBtn from "../../../components/savebtn";
import { successToast } from "../../../helpers/showToast";
import WidgetPosition from "../../../components/widgetpostion";
import React, { useState, useEffect, useCallback } from "react";
import {
  useEditThemeMutation,
  useLazyThemeByIdQuery,
} from "../../../services/settings";
import { ColorPicker, Spin } from "antd";
import useAuth from "../../../hooks/useAuth";

const Widget = () => {
  const [themeManage] = useLazyThemeByIdQuery();
  const [updateTheme] = useEditThemeMutation();
  const [olnStatus, setOlnStatus] = useState("");
  const [load, setLoad] = useState(false);
  const [color, setColor] = useState("");
  const [wComeImg, setwComeImg] = useState("");
  const [widPos, setWidPos] = useState("");
  const [clss, setClss] = useState("");
  const [open, setOpen] = useState(false);
  const [require, setRequire] = useState(false);
  const userData = useAuth();

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      const response = await themeManage(userData?.business_name?.bussiness_id);
      const responseData = response?.data?.data;
      console.log("response: ", response);
      setColor(responseData?.back_ground_color);
      setwComeImg(responseData?.welcome_image);
      setOlnStatus(responseData?.online_status);
      setClss(responseData?.launcher);
      setWidPos(responseData?.widget_position);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [themeManage]);

  const saveData = async () => {
    if (!color || !olnStatus || !widPos || wComeImg === null || clss === null) {
      console.log("require: ", require);
      setRequire(true);
      return;
    }
    const companyId = userData.business_name.bussiness_id;
    try {
      setLoad(true);
      let body = {
        back_ground_color: color,
        welcome_image: wComeImg,
        online_status: olnStatus,
        launcher: clss,
        widget_position: widPos,
      };
      const response = await updateTheme({ body, id: companyId });
      console.log("response: ", response);
      successToast(response.data.message);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
      fetchData();
      setRequire(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLiClick = (index) => {
    setClss(index);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveData();
    }
  };

  const handleColorChange = (color) => {
    const bgClr = typeof color === "string" ? color : color.toHexString();
    if (bgClr !== color) {
      setColor(bgClr);
    }
  };

  return (
    <>
      <div className="stng_rhs">
        <Spin spinning={load} size="medium">
          <div className="agent_head assign_head">
            <h2>Widget Appearance</h2>
          </div>
          <div className="custmoize_theme d_flx align_top">
            <div className="theme_lhs">
              <div className="clr_label">
                <h3>Background Color</h3>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <ul className="clr_bx" style={{ marginBottom: "10px" }}>
                    <li onClick={() => setColor("#414DE1")}>
                      <span style={{ background: "#414DE1" }}></span>
                    </li>
                    <li onClick={() => setColor("#DC6803")}>
                      <span style={{ background: "#DC6803" }}></span>
                    </li>
                    <li onClick={() => setColor("#079455")}>
                      <span style={{ background: "#079455" }}></span>
                    </li>
                    <li onClick={() => setColor("#1D1D1D")}>
                      <span style={{ background: "#1D1D1D" }}></span>
                    </li>
                    <li>
                      <ColorPicker onChange={handleColorChange}>
                        <i className="fa-solid fa-ellipsis"></i>
                      </ColorPicker>
                    </li>
                  </ul>
                  {require && !color && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      *Please fill the required fields
                    </p>
                  )}
                </div>
              </div>
              <div className="clr_label">
                <div>
                  <h3>Welcome Image</h3>
                  <p>Display before operator join conversation.</p>
                </div>
                <div className="gernder_box">
                  <div className="input_radio">
                    <input
                      type="radio"
                      id="html1"
                      name="fav_language"
                      value={wComeImg}
                      className="input-radio"
                      checked={wComeImg === 0}
                      onChange={() => setwComeImg(0)}
                      onKeyDown={handleKeyPress}
                    />
                    <label htmlFor="html1">Agent Collage</label>
                  </div>
                  <div className="input_radio">
                    <input
                      type="radio"
                      id="html2"
                      name="fav_language"
                      value={wComeImg}
                      className="input-radio"
                      checked={wComeImg === 1}
                      onChange={() => setwComeImg(1)}
                      onKeyDown={handleKeyPress}
                    />
                    <label htmlFor="html2">Your Logo</label>
                  </div>
                  {require && wComeImg === null && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      *Please select welcome image
                    </p>
                  )}
                </div>
              </div>
              <div className="clr_label">
                <h3>Online Status</h3>
                <InputBox
                  type="text"
                  placeholder="We reply immediately"
                  value={olnStatus}
                  onChange={(e) => setOlnStatus(e.target.value)}
                  onkeyDown={handleKeyPress}
                />
                {require && !olnStatus && (
                  <p style={{ color: "red", fontSize: "14px" }}>
                    *Please fill the required fields
                  </p>
                )}
              </div>
              <div className="clr_label">
                <h3>Widget Positions</h3>
                <figure
                  onClick={() => setOpen(true)}
                  style={{ marginRight: "10px" }}
                >
                  <img src="./images/monitor.svg" alt="monitor"></img>
                </figure>
                {require && !widPos && (
                  <p style={{ color: "red", fontSize: "14px" }}>
                    *Please select position of widget
                  </p>
                )}
              </div>
              <div className="clr_label">
                <h3>Launcher:</h3>
                <ul className="chat_icon">
                  <li
                    className={clss === 1 ? "active" : null}
                    onClick={() => handleLiClick(1)}
                  >
                    <figure>
                      <img src="./images/chat1.svg" alt="monitor"></img>
                    </figure>
                  </li>
                  <li
                    className={clss === 2 ? "active" : null}
                    onClick={() => handleLiClick(2)}
                  >
                    <figure>
                      <img src="./images/chat2.svg" alt="monitor"></img>
                    </figure>
                  </li>
                  <li
                    className={clss === 3 ? "active" : null}
                    onClick={() => handleLiClick(3)}
                  >
                    <figure>
                      <img src="./images/chat3.svg" alt="monitor"></img>
                    </figure>
                  </li>
                  <li
                    className={clss === 4 ? "active" : null}
                    onClick={() => handleLiClick(4)}
                  >
                    <figure>
                      <img src="./images/chat4.svg" alt="monitor"></img>
                    </figure>
                  </li>
                  {require && clss === null && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      *Please select any launcher
                    </p>
                  )}
                </ul>
              </div>
              <SaveBtn title="Save Changes" onClick={saveData} />
            </div>
            <div className="theme_rhs">
              <div className="chat_design">
                <div className="sara_head" style={{ background: color }}>
                  <figure>
                    <img src="images/tunix.svg" alt="img"></img>
                  </figure>
                  <div>
                    <p>Chat with</p>
                    <h3>Sara</h3>
                  </div>
                </div>
                <div className="reply_tetx" style={{ background: color }}>
                  <i className="fa-solid fa-circle"></i>{" "}
                  <p>{olnStatus ?? "We reply immediately"}</p>
                </div>
                <div className="chat_right" style={{ background: color }}>
                  <p>i’ve been looking for wedding</p>
                </div>
                <div className="chat_left">
                  <p>
                    Let me check that for your real quick! We’ve just had a big
                    delivery in the morning.
                  </p>
                </div>
                <div className="chat_right" style={{ background: color }}>
                  <p>That’s Great ! 😀</p>
                </div>
                <div className="chat_ended">
                  <p>Chat Ended</p>
                </div>
                <div className="star_c">
                  <i className="fa-regular fa-star"></i>
                  <i className="fa-regular fa-star"></i>
                  <i className="fa-regular fa-star"></i>
                  <i className="fa-regular fa-star"></i>
                  <i className="fa-regular fa-star"></i>
                </div>
                <div className="chat_input">
                  <input type="text" placeholder="Enter your message....." />
                  <figure style={{ background: color }}>
                    <img src="./images/send.svg" alt="send" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
      <WidgetPosition
        setOpen={setOpen}
        open={open}
        value={widPos}
        onChange={setWidPos}
      />
    </>
  );
};
export default Widget;
