import React, { useState } from "react";
import LoginButton from "./loginbutton";
import { DatePicker, Dropdown } from "antd";

const Filter = ({ onFromChange, onToChange, onClick, ratings }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleFromChange = (date, dateString) => {
    onFromChange(dateString);
  };

  const handleToChange = (date, dateString) => {
    onToChange(dateString);
  };

  const handleRate = (val, check) => {
    ratings(val, check);
  };

  const items = [
    {
      label: (
        <div className="filter_icon">
          <div
            className={
              window.location.pathname === "/rating" ? "star_filter" : "d_none"
            }
          >
            <div className="rating_star d_flx">
              <label htmlFor="remember1">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </label>
              <input
                type="checkbox"
                id="remember1"
                name="remember1"
                onChange={(e) => handleRate(5, e.target.checked)}
              />
            </div>
            <div className="rating_star d_flx">
              <label htmlFor="remember1">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </label>
              <input
                type="checkbox"
                id="remember1"
                name="remember1"
                onChange={(e) => handleRate(4, e.target.checked)}
              />
            </div>
            <div className="rating_star d_flx">
              <label htmlFor="remember2">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </label>
              <input
                type="checkbox"
                id="remember2"
                name="remember1"
                onChange={(e) => handleRate(3, e.target.checked)}
              />
            </div>
            <div className="rating_star d_flx">
              <label htmlFor="remember3">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </label>
              <input
                type="checkbox"
                id="remember3"
                name="remember1"
                onChange={(e) => handleRate(2, e.target.checked)}
              />
            </div>
            <div className="rating_star d_flx">
              <label htmlFor="remember4">
                <i className="fa-solid fa-star"></i>
              </label>
              <input
                type="checkbox"
                id="remember3"
                name="remember1"
                onChange={(e) => handleRate(1, e.target.checked)}
              />
            </div>
          </div>
          <div className="date_bx">
            <label>From Date</label>
            <div className="input_box pd_input">
              <DatePicker onChange={handleFromChange} />
            </div>
          </div>
          <div className="date_bx">
            <label>To Date</label>
            <div className="input_box pd_input">
              <DatePicker onChange={handleToChange} />
            </div>
          </div>
          <LoginButton name="Apply" click={onClick} />
        </div>
      ),
      key: "0",
    },
  ];

  return (
    <>
      <Dropdown
        open={isOpen}
        onOpenChange={(visible) => setIsOpen(visible)}
        menu={{ items }}
        trigger={["click"]}
        style={{ minWidth: "305px" }} // Set the minimum width here
      >
        <button type="button" className="ellipes_box">
          <figure>
            <img src="./images/filter.svg" alt="filter"></img>
          </figure>
        </button>
      </Dropdown>
    </>
  );
};
export default Filter;
