import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { authReducer, featureReducer } from '../reducers';
import api from '../utils/rtk';

const appReducer = combineReducers({
  auth: authReducer,
  feature: featureReducer,
  [api.reducerPath]: api.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/resetAuth') {
    state = {};
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});

export default store;
