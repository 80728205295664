import { useState } from "react";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { isPasswordPattern } from "../utils/validations";

const Password = ({
  placeholder,
  value,
  onChange,
  login,
  require,
  onKeyDown,
}) => {
  const [showPass, setShowPass] = useState("password");

  const pass = () => {
    if (showPass === "password") {
      setShowPass("text");
    } else {
      setShowPass("password");
    }
  };

  const showError = (errorMessage) => {
    return <p style={{ color: "red", fontSize: 15 }}>{errorMessage}</p>;
  };

  const validateInput = () => {
    if (require) {
      if (!value) {
        return showError(`Please enter ${placeholder}`);
      } else if (!isPasswordPattern(value) && !login) {
        return showError(
          "The password must be greater than or equal to 8 which contains one uppercase, one lowercase, one special characters and one numeric values"
        );
      }
    }
    if (value && !isPasswordPattern(value) && !login) {
      return showError(
        "The password must be greater than or equal to 8 which contains one uppercase, one lowercase, one special characters and one numeric values"
      );
    }
    return null;
  };

  return (
    <div className="input_box">
      <figure className="sms">
        <img src="/images/lock.svg" alt="customer"></img>
      </figure>
      <input
        type={showPass}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <figure className="eye" onClick={pass}>
        {showPass === "password" && (
          <EyeInvisibleFilled
            style={{
              fontSize: "22px",
              color: "#9aa4b2",
            }}
          />
        )}
        {showPass === "text" && (
          <EyeFilled
            style={{
              fontSize: "22px",
              color: "#9aa4b2",
            }}
          />
        )}
      </figure>
      {validateInput()}
    </div>
  );
};
export default Password;
