import { Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MessageView } from "./messageView";

const BuildView = ({ setLiveMsg, liveMsg }) => {
  const items = [
    {
      label: (
        <ul
          role="menu"
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="button-types-dropdown"
        >
          <li class="dropdown-header">Add Button</li>
          <li>
            <a>
              <figure>
                <img src="../images/bk_arrow.svg" alt="dots" />
              </figure>
              <span class="gBXVrn"> Default Button </span>
              <span class="bTkyvh"> Suggested Replies </span>
            </a>
          </li>
          <li>
            <a>
              <figure>
                <img src="../images/prefix.svg" alt="dots" />
              </figure>
              <span class="gBXVrn"> Link Button </span>
              <span class="bTkyvh"> Add links to button </span>
            </a>
          </li>
          <li>
            <a>
              <figure>
                <img src="../images/sb_btn.svg" alt="dots" />
              </figure>
              <span class="gBXVrn"> Submit Button </span>
              <span class="bTkyvh"> Send data on button press </span>
            </a>
          </li>
        </ul>
      ),
      key: "0",
    },
  ];

  const handleAddTextBox = () => {};

  const handleDeleteTextBox = (index) => {};

  const hanldeText = (e, index) => {};

  const handleSave = () => {};

  return (
    <>
      <div className="bot_build_page">
        <div className="bot_name_input">
          <input type="text" value="Default Welcome Intent" />
        </div>
        <div class="SmartReplyStyles__ReplayWrapper-sc-4t1kfh-62 jnzQve">
          <div class="SmartReplyStyles__ReplaySelector-sc-4t1kfh-63 gjxNwZ">
            <h4>Bot Says</h4>
            <p title="Setup a welcome message for your users.">
              Setup a welcome message for your users.
            </p>
          </div>
        </div>
        <div className="edt_msg">
          <div className="scrl_bx">
            <h3>Enter Welcome Message</h3>
            <div className="py_ld">
              <p>
                Add text, buttons, images, custom payload messages
                <Link to="">personalize messages</Link> and{" "}
                <Link to="">dynamic message</Link>
              </p>
            </div>
            <hr />
            {liveMsg?.map((item, index) =>
              MessageView(item, items, setLiveMsg, liveMsg)
            )}
            <div className="btn_grps">
              <button type="button" onClick={handleAddTextBox}>
                + Text
              </button>
              <button type="button">+ Buttons</button>
              <button type="button">
                + More{" "}
                <img
                  className="drp_img"
                  src="../images/drp.svg"
                  alt="img"
                ></img>
                <div className="drp_dwn">
                  <h3>RICH MESSAGES</h3>
                  <ul>
                    <li>
                      <img src="../images/img.svg" alt="img" /> Image{" "}
                      <span>Image with caption</span>
                    </li>
                  </ul>
                </div>
              </button>
            </div>
            <div className="dnc_msg">
              <h5>Dynamic Message</h5>
              <div className="csat_toggle d_flx">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="lgt_cnt">
              <p>
                Dynamic messaging helps you to fetch your custom data and send
                it to the end users. Below are the two options to send a dynamic
                message.
              </p>
              <div className="inln_cd">
                <div className="input_radio">
                  <input
                    type="radio"
                    id="html1"
                    name="fav_language"
                    className="input-radio"
                  />
                  <label htmlFor="html1">Inline Code</label>
                </div>
              </div>
              <p>
                Write custom code to retrieve or send data to your database or
                API <Link to="">Know more</Link>
              </p>
              <div className="slc_bx"></div>
              <div className="inln_cd">
                <div className="input_radio">
                  <input
                    type="radio"
                    id="html2"
                    name="fav_language"
                    className="input-radio"
                  />
                  <label htmlFor="html2">Webhook</label>
                </div>
              </div>
              <p>
                Connect your Webhook or API to retrieve or send data to your
                server or database <Link to="">Know more</Link>
              </p>
              <div className="slc_bx"></div>
            </div>
          </div>
          <div className="sv_cncl_btn">
            <button type="button" className="cncl_btn">
              Delete
            </button>
            <button type="button" className="cncl_btn">
              Discard
            </button>
            <button type="button" className="sv_btn" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default BuildView;
