import { useNavigate } from "react-router-dom";

const FormHead = ({ title }) => {
  const history = useNavigate();
  return (
    <div className="form_head text_center">
      <figure onClick={() => history("/")} style={{ cursor: "pointer" }}>
        <img src="/images/logo_black.svg" alt="customer"></img>
      </figure>
      <h1>{title}</h1>
    </div>
  );
};
export default FormHead;
