/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import BotSidebar from "../../components/botsidebar";
import Stepper from "../../components/stepper";
import GoBack from "../../components/goback";
import BotSetup from "./features/botsetup";
import { useDispatch } from "react-redux";
import { removeStepperData } from "../../reducers/featureSlice";

const BotProfile = () => {
  const dispatch = useDispatch();
  const handleRemoveButtonClick = () => {
    dispatch(removeStepperData({ stepperData: "template" }));
  };
  return (
    <>
      <Layout>
        <section className="integration_main">
          {/* <BotSidebar /> */}
          <div className="bg_bx">
            <GoBack
              location="/bot_template"
              onClick={handleRemoveButtonClick}
            />
            <Stepper />
            <BotSetup />
          </div>
        </section>
      </Layout>
    </>
  );
};
export default BotProfile;
