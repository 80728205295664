import React from "react";

const Customer = () => {
  return (
    <div className="conta_iner">
      <div className="d_flx al_sc">
        <div className="customer_lhs" data-aos="fade-in-right">
          <ul>
            <li>
              <figure>
                <img src="/images/customer1.png" alt="customer"></img>
              </figure>
            </li>
            <li>
              <figure>
                <img src="/images/customer2.png" alt="customer"></img>
              </figure>
            </li>
          </ul>
          <ul>
            <li>
              <figure>
                <img src="/images/customer3.png" alt="customer"></img>
              </figure>
            </li>
            <li>
              <figure>
                <img src="/images/customer4.png" alt="customer"></img>
              </figure>
            </li>
            <li>
              <figure>
                <img src="/images/customer5.png" alt="customer"></img>
              </figure>
            </li>
          </ul>
        </div>
        <div className="customer_cntr text_center" data-aos="zoom-out-up">
          <h2>Join the best</h2>
          <h3 className="goudy">Providing the best experiences for 100,000+ customers</h3>
          <button type="button" className="secondary_btn">
            Start your free trial
          </button>
        </div>
        <div className="customer_rhs" data-aos="fade-in-right">
          <ul>
            <li>
              <figure>
                <img src="/images/customer6.png" alt="customer"></img>
              </figure>
            </li>
            <li>
              <figure>
                <img src="/images/customer7.png" alt="customer"></img>
              </figure>
            </li>
            <li>
              <figure>
                <img src="/images/customer8.png" alt="customer"></img>
              </figure>
            </li>
          </ul>
          <ul>
            <li>
              <figure>
                <img src="/images/customer9.png" alt="customer"></img>
              </figure>
            </li>
            <li>
              <figure>
                <img src="/images/customer10.png" alt="customer"></img>
              </figure>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Customer;
