import { UploadMedia } from "../../../services/uploadmedia";
import InputBox from "../../../components/inputbox";
import SaveBtn from "../../../components/savebtn";
import { Spin, Tour } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAddBotAvtrMutation,
  useLazyAllAvatarQuery,
} from "../../../services/createbot";
import { useDispatch } from "react-redux";
import { setStepperData } from "../../../reducers/featureSlice";

const BotSetup = () => {

  let history = useNavigate();
  const [getAvatar] = useLazyAllAvatarQuery();
  const [addAvatar] = useAddBotAvtrMutation();
  const [domainRequire, setDomainRequire] = useState(false);
  const [profilePic, SetProfilePic] = useState(null);
  const [avatarData, setAvatarData] = useState([]);
  const [profileImg, setProfileImg] = useState("");
  const [openTour, setOpenTour] = useState(false);
  const [addDomain, setAddDomain] = useState("");
  const [require, setRequire] = useState(false);
  const [avatarId, setAvatarId] = useState("");
  const [botName, setBotName] = useState("");
  const [domain, setDomain] = useState([]);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const ref1 = useRef(null);

  const steps = [
    {
      title: "Domain setup",
      description: "To add a domain, click this plus sign.",
      target: () => ref1.current,
    },
  ];

  const closeTour = () => {
    setOpenTour(false);
  };
  const HandOffPage = async () => {
    if (!botName || !profileImg || !domain?.length ) {
      if(addDomain && !domain?.length){
        setOpenTour(true);
      }
      setRequire(true);
      return;
    }
    let mediaResponse;
    let proMedia;
    let avaId;
    try {
      setLoad(true);
      if (profilePic) {
        mediaResponse = await UploadMedia(profilePic);
        proMedia = await mediaResponse.data.find((media) => media?.id);
        let body = {
          image: proMedia?.id,
        };
        const avatarRes = await addAvatar(body);
        avaId = avatarRes?.data?.data?.id;
      }
      const botAvatarId = avaId ?? avatarId;
      history("/handoff", {
        state: {
          bName: botName,
          bAvatar: botAvatarId,
          bLang: 1,
          bTemp: state?.id,
          handOffPic: profilePic,
          handOfFname: botName,
          handOffDomain: domain,
        },
      });
      dispatch(setStepperData({ stepperData: "botProfile" }));
    } catch (error) {
      console.log("Error while uploading image", error);
    } finally {
      setLoad(false);
      setBotName("");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAvatar();
        console.log("response: ", response?.data?.data);
        setAvatarData(response?.data?.data);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    if (state?.id) {
      fetchData();
    } else {
      history("/bot_template");
    }
    if (state?.name) {
      setBotName(state?.name);
    }
  }, [getAvatar, state]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      SetProfilePic(file);
      setProfileImg(URL.createObjectURL(file));
    }
  };

  const handleSltAvatar = (id, pic) => {
    if (id || pic) {
      setAvatarId(id);
      setProfileImg(pic);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      HandOffPage();
    }
  };

  const domainSetup = () => {
    if (!addDomain) {
      setDomainRequire(true);
      setRequire(true);
      return;
    }
    if (addDomain) {
      setDomain([...domain, addDomain]);
      setDomainRequire(false);
      setRequire(false);
      setAddDomain("");
    }
  };

  const handleDomainKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      domainSetup();
    }
  };

  const DeleteDomainElemet = (index) => {
    domain.splice(index, 1);
    setDomain([...domain]);
  };

  return (
    <>
      <div className="bot_setup">
        <Spin spinning={load} size="medium">
          <h2>Set up your bot:</h2>
          <div className="bot_detail">
            <label>Bot Name:</label>
            <InputBox
              type="text"
              placeholder="Ex: Alex Bot"
              value={botName}
              onChange={(e) => setBotName(e.target.value)}
              require={require}
              reqValue={"bot name"}
              onkeyDown={handleKeyPress}
            />
          </div>
          <div className="bot_detail align_base">
            <label>Bot Photo:</label>
            <div>
              <div className="proifle_pic">
                <figure>
                  {profilePic || profileImg ? (
                    <img src={profileImg} alt="bot template" />
                  ) : (
                    <img
                      src="./images/photo-placeholder.png"
                      alt="bot template"
                    />
                  )}
                </figure>
                {require && !profileImg && (
                  <span
                    style={{
                      color: "red",
                      fontSize: 15,
                    }}
                  >
                    *Please choose following bot avatar or upload your own
                  </span>
                )}
              </div>
              <p className="m_20">Choose a bot avatar or upload your own</p>
              <ul>
                <li>
                  <div className="proifle_pic">
                    <figure
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <i
                        className="fa-solid fa-upload"
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          marginBottom: 5,
                        }}
                      />
                      <p
                        style={{
                          fontSize: 10,
                        }}
                      >
                        Upload
                      </p>
                      <input
                        type="file"
                        onChange={handleFileUpload}
                        onkeyDown={handleKeyPress}
                      />
                    </figure>
                  </div>
                </li>
                {avatarData?.map((avatar) => (
                  <li key={avatar?.id}>
                    {" "}
                    <figure
                      onClick={() =>
                        handleSltAvatar(
                          avatar?.id,
                          avatar?.image?.media_file_url
                        )
                      }
                    >
                      <img src={avatar?.image?.media_file_url} alt="avtar" />
                    </figure>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="bot_detail">
            <label>Default Lang:</label>
            <InputBox type="text" placeholder="English" disable={true} />
          </div>
          <div className="agent_head d_flx m_0">
            <h2>Domain setup</h2>
          </div>
          <div>
            <ul className="upload_bot">
              {domain?.map((item, index) => (
                <li key={index}>
                  <p>{item}</p>
                  <i
                    className="fa-solid fa-xmark"
                    onClick={() => DeleteDomainElemet(index)}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="add_query">
            <InputBox
              placeholder="Add Domain"
              type="text"
              value={addDomain}
              onChange={(e) => setAddDomain(e.target.value)}
              onkeyDown={handleDomainKeyPress}
              require={domainRequire || require}
              reqValue={"domain name"}
            />
            <i ref={ref1} className="fa-solid fa-plus" onClick={domainSetup}></i>
          </div>
          <SaveBtn title="Save and proceed" onClick={HandOffPage} />
        <Tour open={openTour} onClose={closeTour} steps={steps} />
        </Spin>
      </div>
    </>
  );
};
export default BotSetup;
