import React from "react";

const Hero = () => {
  return (
    <section className="hero_area clr_white">
      <div className="conta_iner">
        <div className="d_flx">
          <div className="hero_lhs w_48">
            <h1>
              TunixBot AI <br />
              already speaks
              <br /> customer service
            </h1>
            <p className="mrg_tbbt">
              Only Tunixbot AI is built on billions of real customer service
              interactions. It understands customer experience, so you get
              personalised support at a lower cost from day one.
            </p>
            <button type="button" className="btn_primary">
              Contact Sales
            </button>
          </div>
          <div className="hero_rhs w_48">
            <figure>
              <img src="/images/hero.png" alt="hero"></img>
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
