import { Link } from "react-router-dom";

const AgentSidebar = (props) => {
  return (
    <>
      <div className={props.activeCls ? 'sidebar active' : "sidebar"}>
        <figure className="logo_sidebar">
          <img src="/images/logo_sidebar.svg" alt="logo"></img>
        </figure>
        <ul>          <li
          className={
            window.location.pathname === "/agent-dashboard" ? "active" : ""
          }
        >
          <Link to="/agent-dashboard">
            <figure>
              <img src="/images/dashboard.svg" alt="logo"></img>
            </figure>
            <span>Dashboard</span>
          </Link>
        </li>
          <li
            className={
              window.location.pathname === "/agent-conversation" ? "active" : ""
            }
          >
            <Link to="/agent-conversation">
              <figure>
                <img src="/images/customer-service.svg" alt="logo"></img>
              </figure>
              <span>Conversation</span>
            </Link>
          </li>
          <li
            className={
              window.location.pathname === "/botintegration" ||
                window.location.pathname === "/bot_template" ||
                window.location.pathname === "/botprofile" ||
                window.location.pathname === "/handoff" ||
                window.location.pathname === "/success" ||
                window.location.pathname === "/defaultflow" ||
                window.location.pathname === "/domainsetup" ||
                window.location.pathname === "/training" ||
                window.location.pathname === "/managebot"
                ? "active"
                : ""
            }
          >
          </li></ul>
      </div>
    </>
  );
};
export default AgentSidebar;
