import { isEmail, validMobileNo } from "../utils/validations";
const InputBox = ({
  placeholder,
  type,
  value,
  onChange,
  require,
  reqValue,
  onkeyDown,
  disabled,
}) => {
  const showError = (errorMessage) => {
    return <p style={{ color: "red", fontSize: "14px" }}>*{errorMessage}</p>;
  };

  const validateInput = () => {
    if (require) {
      if (!value && !reqValue) {
        return showError(`Please Enter ${placeholder}`);
      } else if (!value && reqValue) {
        return showError(`Please enter ${reqValue}`);
      } else if (placeholder === "Enter your email" && !isEmail(value)) {
        return showError(`Please enter valid email`);
      }
    }

    if (value) {
      if (
        placeholder === "Enter your contact number" &&
        !validMobileNo(value)
      ) {
        return showError(`Please enter valid contact number`);
      }
    }

    if (value && !isEmail(value) && placeholder === "Enter your email") {
      return showError("Enter valid email id");
    }

    return null;
  };

  return (
    <div className="input_box pd_input">
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onKeyDown={onkeyDown}
        style={disabled ? { cursor: "not-allowed" } : null}
      />
      {validateInput()}
    </div>
  );
};
export default InputBox;
