/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import BotSidebar from "../../components/botsidebar";
import Stepper from "../../components/stepper";
import GoBack from "../../components/goback";
import Info from "./features/info";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeStepperData } from "../../reducers/featureSlice";

const Handoff = () => {
  const location = useLocation();
  const { state } = location;

  const dispatch = useDispatch();
  const handleRemoveButtonClick = () => {
    dispatch(removeStepperData({ stepperData: "botProfile" }));
  };
  return (
    <>
      <Layout>
        <section className="integration_main">
          {/* <BotSidebar /> */}
          <div className="bg_bx">
            <GoBack
              location="/botprofile"
              stateId={state.id}
              stateName={state.handOfFname}
              onClick={handleRemoveButtonClick}
            />
            <Stepper />
            <Info />
          </div>
        </section>
      </Layout>
    </>
  );
};
export default Handoff;
