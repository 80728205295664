import React from "react";

const About = () => {
  return (
    <section className="ab_sc">
      <div className="conta_iner">
        <div className="d_flx al_sc">
          <div className="abt_lhs w_48" data-aos="fade-right">
            <figure>
              <img src="/images/abt.png" alt="hero"></img>
            </figure>
          </div>
          <div className="abt_lhs w_48" data-aos="fade-left">
            <h2>
              Up your efficiency
              <br /> without sacrificing CX
            </h2>
            <p className="gry_txt">
              Drive cost per interaction down and CSAT up with a custom-built
              virtual agent powered by conversational and generative AI that:
            </p>
            <ul className="blue_list">
              <li>
                Automates 60% of customer requests across digital support
                channels, including chat, email, messaging, and more
              </li>
              <li>Provides instant, 24/7 support in 109 languages</li>
              <li>Delivers instant, measurable ROI and cost savings</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
