/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Empty, Rate } from "antd";
import { useLazySatisfiedRatingQuery } from "../../../services/dashboard";
import useAuth from "../../../hooks/useAuth";

const Satisfaction = () => {
  const [rating] = useLazySatisfiedRatingQuery();
  const [ratingData, setRatingData] = useState(null);
  const userData = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bId = userData?.business_name?.bussiness_id;
        console.log("bId: ", bId);
        if (!bId) return;
        const response = await rating(bId);
        setRatingData(response?.data?.data);
        console.log("response?.data?.data: ", response?.data?.data);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    fetchData();
  }, [rating, userData]);
  return (
    <div className="shd_bx chat_bot w_49">
      <h3>Customer Satisfaction</h3>
      <p>Average rating based on all satisfactory interactions today</p>

      <ul className="star">
        {ratingData ? (
          <Rate
            allowHalf
            disabled
            value={ratingData}
            style={{ fontSize: 50, color: "#414de1" }}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No rating is present."
          />
        )}
      </ul>
      <p>Satisfactory interactions vs Unsatisfactory interactions</p>
    </div>
  );
};
export default Satisfaction;
