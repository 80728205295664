import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const manageBotApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    BotList: builder.mutation({
      query: (body) => ({
        url: END_POINTS.botList,
        method: "POST",
        body,
      }),
    }),
    OptionNoParent: builder.query({
      query: (id) => ({
        url: `${END_POINTS.OptionNoParent}${id}/`,
        method: "GET",
      }),
    }),
    optionById: builder.query({
      query: (id) => ({
        url: `${END_POINTS.optionById}${id}/`,
        method: "GET",
      }),
    }),
    getDomainById: builder.query({
      query: (id) => ({
        url: `${END_POINTS.getDomainById}${id}/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useBotListMutation,
  useLazyOptionNoParentQuery,
  useLazyOptionByIdQuery,
  useLazyGetDomainByIdQuery,
} = manageBotApi;
