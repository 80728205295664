import { Pie } from "react-chartjs-2";
import { useLazyPieDataQuery } from "../../../services/agentdashboard";
import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Empty } from "antd";

ChartJS.register(ArcElement, Tooltip, Legend);
export function AgntPieChart() {
  const [pieData] = useLazyPieDataQuery();
  const [status, setStatus] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await pieData();
        console.log(
          "response:::::::::::::::::::>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ",
          response
        );
        if (response?.data?.data !== 0) {
          setStatus(response?.data?.data);
        } else {
          setStatus(null);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    };
    fetchData();
  }, [pieData]);

  const data = {
    labels: ["active chat", "resolved chat"],
    datasets: [
      {
        label: "Total  Percentage",
        data: [status?.active_percentage, status?.resolved_percentage],
        backgroundColor: ["#414DE1", "#079455"],
        borderColor: ["#5856d6"],
        borderWidth: 1,
        hoverBackgroundColor: ["#414DE1", "#079455"],
        hoverBorderColor: ["#5856d6"],
      },
    ],
  };

  return status === null ? (
    <>
      <Empty />
    </>
  ) : (
    <Pie className="canvas" data={data} />
  );
}
