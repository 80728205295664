import React, { useState } from "react";
import LabelInput from "../../../components/label";
import InputBox from "../../../components/inputbox";
import SaveBtn from "../../../components/savebtn";
import { useHelpMutation } from "../../../services/help";
import { isAlphabete, validMobileNo } from "../../../utils/validations";
import { errorToast, successToast } from "../../../helpers";

const EditForm = () => {
  const [fname, setFname] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [queries, setQueries] = useState();
  const [description, setDescription] = useState();
  const [required, setRequire] = useState(false);
  const [help] = useHelpMutation();
  const [load, setLoad] = useState(false);

  const saveProfile = async () => {
    if (!fname || !email) {
      console.log("enter your details");
      setRequire(true);
      return;
    }
    if (phone) {
      if (!validMobileNo(phone)) {
        return;
      }
    }
    let response;
    try {
      setLoad(true);
      let body = {
        queries: queries,
        description: description,
        customer_query_first_name: fname,
        customer_query_email: email,
        customer_query_phone_no: phone,
      };
      response = await help(body);
      successToast(response.data.message);
    } catch (error) {
      errorToast(response.error.data.message);
      console.log("error: ", error);
    } finally {
      setLoad(false);
      setRequire(false);
      setFname("");
      setEmail("");
      setPhone("");
      setQueries("");
      setDescription("");
    }
  };

  const phNumber = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    setPhone(numericValue);
  };

  const handleFname = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setFname(input);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveProfile();
    }
  };
  return (
    <>
      <div className="d_flx prf_field">
        <div className="fld_lhs">
          <div>
            <LabelInput Name="Name :" />
            <InputBox
              placeholder="Enter your name"
              type="text"
              value={fname}
              onChange={handleFname}
              require={required}
              reqValue="your first name"
              onkeyDown={handleKeyPress}
            />
          </div>
          <div>
            <LabelInput Name="Email :" />
            <InputBox
              placeholder="Enter your email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              require={required}
              reqValue="your email"
              onkeyDown={handleKeyPress}
            />
          </div>
          <div>
            <LabelInput Name="Phone Number :" />
            <InputBox
              placeholder="Enter your contact number"
              type="text"
              value={phone}
              onChange={phNumber}
              require={required}
              onkeyDown={handleKeyPress}
              reqValue="your phone number"
            />
          </div>
          <div>
            <LabelInput Name="Queries :" />
            <InputBox
              placeholder="Enter your queries"
              type="text"
              value={queries}
              onChange={(e) => setQueries(e.target.value)}
              onkeyDown={handleKeyPress}
              require={required}
              reqValue="your queries"
            />
          </div>
          <div>
            <LabelInput Name="Description :" />
            <textarea
              className="dscrp_bx"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onkeyDown={handleKeyPress}
              require={required}
            />
            {required && !description && (
              <p style={{ color: "red", fontSize: 15 }}>
                *Please enter description
              </p>
            )}
          </div>
          <SaveBtn title="Submit" onClick={saveProfile} load={load} />
        </div>
      </div>
    </>
  );
};
export default EditForm;
