export const connectSocket = (id,sessionId) => {
    const ws = new WebSocket(`wss://dev-ai-chatbot.webdevelopmentsolution.net/ws/basicbot-agent-user/${id}/${sessionId}/`);
    ws.onopen = () => {
        console.log("WebSocket connected");
    };
    ws.onmessage = (e) => {
      // a message was received
      console.log(e.data);
    };
    ws.onerror = (e) => {
      // an error occurred
      console.log(e);
    };
    ws.onclose = (e) => {
      // connection closed
      console.log(e.code, e.reason, e);
    };
    return ws;
  };
