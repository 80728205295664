import "react-tabs/style/react-tabs.css";
import { useLazyPreviousChatQuery } from "../../../services/conversation";
import { Avatar, Empty, Skeleton } from "antd";
import formatTime from "../../../utils/timeago";
import { connectSocket } from "../../../utils/webSocket";
import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ConfirmEndChat from "../../../components/confirmchatend";
import { useLazyMarkAsUnresolvedQuery } from "../../../services/agentconversation";
import useAuth from "../../../hooks/useAuth";

const ConversationPanel = ({ idFromLeftPanel, tabSlt, sendChatEnd }) => {
  const userData = useAuth();
  const [socket, setSocket] = useState();
  const [previousChat] = useLazyPreviousChatQuery();
  const [mardAsResolved] = useLazyMarkAsUnresolvedQuery();
  const [chats, setChats] = useState();
  const [message, setMessage] = useState("");
  const chatContainerRef = useRef(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const fetchChat = async () => {
      try {
        setLoad(true);
        const response = await previousChat(idFromLeftPanel);
        console.log("response: ", response);
        const responseData = response?.data?.data;
        setChats(responseData);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };
    if (idFromLeftPanel) {
      fetchChat();
      console.log("idFromLeftPanel: ", idFromLeftPanel);
      console.log("tabSlt: ", tabSlt);
    }
  }, [idFromLeftPanel, previousChat]);

  useEffect(() => {
    setChats([]);
  }, [tabSlt]);

  useEffect(() => {
    if (idFromLeftPanel) {
      const connection = connectSocket(userData?.id, idFromLeftPanel);
      console.log("connection: ", connection);
      setSocket(connection);
    }
  }, [idFromLeftPanel, userData]);

  const sendMessage = () => {
    if (message) {
      const messageData = {
        input_text: message,
      };
      socket.send(JSON.stringify(messageData));
      setMessage("");
    }
  };

  const endChat = () => {
    console.log("chat ended");
    socket.send(JSON.stringify({ input_text: "chat_ended" }));
  };

  const markAsUnResolved = async () => {
    if (idFromLeftPanel) {
      try {
        setLoad(true);
        const response = await mardAsResolved(idFromLeftPanel);
        const responseData = response?.data?.data;
        console.log("response: ", responseData);
        sendChatEnd("unResolved");
        setChats([]);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    }
  };

  const handleNewMessage = (event) => {
    const newMessage = JSON.parse(event.data);
    setChats((prevChats) => [...prevChats, newMessage]);
  };

  useEffect(() => {
    if (socket) {
      socket.addEventListener("message", handleNewMessage);
    }
    return () => {
      socket?.removeEventListener("message", handleNewMessage);
    };
  }, [socket]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chats]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleAgentChat = (chat) => {
    if (!chat?.user_input) {
      switch (chat?.type) {
        case "chat_ended":
          return (
            <>
              <p>{chat?.Bot?.welcome_message}</p>
              {chat?.Bot?.options?.map((opt, index) => (
                <p key={index}>{opt?.name}</p>
              ))}
            </>
          );
        case "text_type":
          return <p>{chat?.message?.name}</p>;
        default:
          return <p>{chat?.message?.name}</p>;
      }
    } else {
      switch (chat?.user_input?.type) {
        case "option_type":
          return (
            <>
              {chat?.user_input?.Bot?.welcome_message && (
                <p>{chat?.user_input?.Bot?.welcome_message}</p>
              )}
              <div className="options_box">
                {chat?.user_input?.Bot?.options?.map((opt) => (
                  <p>{opt?.name}</p>
                ))}
              </div>
            </>
          );
        case "text_type":
          return <p>{chat?.user_input?.message?.name}</p>;
        case "message_type":
          return <p>{chat?.user_input?.message?.name}</p>;
        case "wait_type":
          return <p>{chat?.user_input?.message?.name}</p>;
        case "chat_ended":
          return (
            <>
              <p className="welcome_msg">
                {chat?.user_input?.Bot?.welcome_message}
              </p>
              <div
                className="options_box"
                style={{ justifyContent: "flex-end" }}
              >
                {chat?.user_input?.Bot?.options?.map((opt, index) => (
                  <p key={index}>{opt?.name}</p>
                ))}
              </div>
            </>
          );
        default:
          return <p>{chat?.message?.name}</p>;
      }
    }
  };

  const handleResponse = (chat) => {
    if (chat?.type === "resolved_type") {
      sendChatEnd("unResolved");
      setChats([]);
    }
    switch (chat?.user_input?.type) {
      case "option_type":
        return (
          <>
            {chat?.user_input?.Bot?.welcome_message && (
              <p className="welcome_msg">
                {chat?.user_input?.Bot?.welcome_message}
              </p>
            )}
            <div className="options_box">
              {chat?.user_input?.Bot?.options?.map((opt) => (
                <p>{opt?.name}</p>
              ))}
            </div>
          </>
        );
      case "text_type":
        return <p>{chat?.user_input?.message?.name}</p>;
      case "message_type":
        return <p>{chat?.user_input?.message?.name}</p>;
      case "wait_type":
        return <p className="welcome_msg">{chat?.user_input?.message?.name}</p>;
      case "resolved_type":
        return <p className="welcome_msg">{chat?.user_input?.message?.name}</p>;
      default:
        return <p>{chat?.message?.name}</p>;
    }
  };

  const capitalizeFirstLetter = (word) => {
    if (word.length === 0) {
      return "";
    }
    return word[0].toUpperCase();
  };

  return (
    <div className="conv_rightPanel">
      <div className="agent_head assign_head">
        <h2>Conversation</h2>
        {(tabSlt === 0 || !tabSlt) && idFromLeftPanel ? (
          <div className="btn_grp">
            <button
              type="button"
              className="msg_end"
              onClick={() => ConfirmEndChat(() => endChat(), "end")}
            >
              End chat
            </button>{" "}
            <button
              type="button"
              className="unrsld"
              onClick={() =>
                ConfirmEndChat(() => markAsUnResolved(), "Unresolved")
              }
            >
              Mark as unresolved
            </button>{" "}
          </div>
        ) : null}
      </div>
      <Skeleton
        loading={load}
        active
        block={true}
        shape={"square"}
        size={"large"}
      >
        <div className="agent_chat_box" ref={chatContainerRef}>
          {chats?.length ? (
            chats?.map((chat, index) =>
              chat?.user_name === userData?.first_name ? (
                chat?.user_input?.type === "agent_assigned" ? (
                  <div className="center_chat">
                    <div className="assigned_message">
                      <p>{chat?.user_input?.message?.name}</p>
                    </div>
                  </div>
                ) : (
                  <div
                    className="cobersation_chat"
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div className="alex_box">
                      <div>
                        <h3
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {chat?.user_name === userData?.first_name
                            ? "You"
                            : chat?.user_name}
                        </h3>
                        {handleAgentChat(chat)}
                        <span>{formatTime(chat.timestamp)}</span>
                      </div>
                      <figure>
                        <img
                          src={
                            chat?.profile_pic
                              ? chat?.profile_pic
                              : "/images/frame.png"
                          }
                          alt="alex"
                        />
                      </figure>
                    </div>
                  </div>
                )
              ) : chat?.user_input?.type === "agent_assigned" ? (
                <div className="assigned_line">
                  <p>{chat?.user_input?.message?.name}</p>
                </div>
              ) : (
                <div className="cobersation_chat" key={index}>
                  <div className="alex_box">
                    <figure>
                      {chat?.profile_pic ? (
                        <img src={chat?.profile_pic} alt="user_pic" />
                      ) : (
                        <Avatar
                          style={{
                            backgroundColor: "#ECF6FD",
                            color: "#414DE1",
                          }}
                        >
                          {capitalizeFirstLetter(chat?.user_name)}
                        </Avatar>
                      )}
                    </figure>
                    <div>
                      <h3>
                        {chat?.user_name === userData?.first_name
                          ? "You"
                          : chat?.user_name}
                      </h3>
                      {handleResponse(chat)}
                      <span>{formatTime(chat?.timestamp)}</span>
                    </div>
                  </div>
                </div>
              )
            )
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              description="No Chats Available"
            />
          )}
        </div>
      </Skeleton>
      {(tabSlt === 0 || !tabSlt) && idFromLeftPanel ? (
        <Tabs className="reply_tab">
          <TabList>
            <Tab>Reply</Tab>
          </TabList>
          <TabPanel>
            <div className="type_msg">
              <textarea
                placeholder="Type Your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              <button type="button" className="msg_send" onClick={sendMessage}>
                Send
              </button>
            </div>
          </TabPanel>
        </Tabs>
      ) : null}
    </div>
  );
};

export default ConversationPanel;
