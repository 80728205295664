/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import SettingSidebar from "../../components/settingsidebar";
import Notification from "./features/notification";

const PushNotification = () => {
  return (
    <>
      <Layout>
        <section className="setting_page d_flx align_top">
          <SettingSidebar />
          <Notification />
        </section>
      </Layout>
    </>
  );
};
export default PushNotification;
