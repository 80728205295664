import React, { useState } from "react";
import Header from "./header";
import Footer from "./footer";
import MainHeader from "./main-header";
import Sidebar from "./sidebar";
import AgentSidebar from "./agent-sidebar";
import useAuth from "../hooks/useAuth";

const Layout = ({ children }) => {
  const [activeCls, setActiveCls] = useState(false);

  const userRole = useAuth();

  const renderSidebar = () => {
    if (userRole?.role === 2) {
      return <AgentSidebar activeCls={activeCls} />;
    } else {
      return <Sidebar activeCls={activeCls} />;
    }
  };

  return (
    <div>
      {window.location.pathname === "/" ? <Header /> : null}
      {window.location.pathname === "/" ||
      window.location.pathname === "/login" ||
      window.location.pathname === "/forgotpassword" ||
      window.location.pathname === "/resetpassword" ||
      window.location.pathname === "/signup" ? null : (
        <>
          <MainHeader setActiveCls={setActiveCls} activeCls={activeCls} />
          {renderSidebar()}
        </>
      )}
      {children}
      {window.location.pathname === "/" ? <Footer /> : null}{" "}
    </div>
  );
};
export default Layout;
