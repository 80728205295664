/* eslint-disable jsx-a11y/img-redundant-alt */

import { Bar } from "react-chartjs-2";
import { useLazyAvgTalkToHumanQuery } from "../../../services/dashboard";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function ChatBot() {
  const [activeChat, setActiveChat] = useState([]);
  const [load, setLoad] = useState(false);
  const [avgTalkToHuman] = useLazyAvgTalkToHumanQuery();

  const labels = [];

  const options = {
    responsive: true,
    plugins: {
      legend: false,
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    const totalClient = async () => {
      let res;
      try {
        setLoad(true);
        res = await avgTalkToHuman();
        console.log("res: ", res?.data);
        setActiveChat(res?.data?.data);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };
    totalClient();
  }, [avgTalkToHuman]);

  const data = {
    labels,
    datasets: [
      {
        label: "Queries",
        data: activeChat,
        backgroundColor: ["#414de1"],
        borderColor: ["#414de1"],
        borderWidth: 1,
        hoverBackgroundColor: ["#414de1"],
        hoverBorderColor: ["#414de1"],
        borderRadius: 25,
        barThickness: 15,
      },
    ],
  };

  return (
    <div className="shd_bx chat_bot w_49">
      <h3>Number Of Queries Per Day</h3>
      <Spin spinning={load} size="medium">
        <Bar options={options} data={data} />
      </Spin>
    </div>
  );
}
