import {
  useConvsnHistoryByIdMutation,
  useLazyAgentByIdQuery,
} from "../../../services/agent";
import UserInput from "../../../components/userinput";
import Email from "../../../components/email";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Empty, Skeleton } from "antd";
import { timesAgo } from "../../../utils/timeago";
import { AgntPieChartById } from "./piechart";

const AgentDetail = () => {
  const location = useLocation();
  const { state } = location;
  const [fstName, setFstName] = useState();
  const [lstName, setLstName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [gender, setGender] = useState();
  const [proPic, setProPic] = useState();
  const [load, setLoad] = useState(false);
  const [AgentById] = useLazyAgentByIdQuery();
  const [convsnHistory] = useConvsnHistoryByIdMutation();
  const [convrsHis, setConvrsHis] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AgentById(state?.key);
        const responseData = response?.data?.data;
        console.log("response: ", response?.data);
        setFstName(responseData?.first_name);
        setLstName(responseData?.last_name);
        setPhone(responseData?.phone_no);
        setEmail(responseData?.email);
        setGender(responseData?.gender);
        setProPic(responseData?.profile_picture?.media_file_url);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    const fetchConvrsHis = async () => {
      try {
        setLoad(true);
        let body = {
          page: 1,
          length: 10,
          search_value: "",
        };
        const response = await convsnHistory({ body, id: state?.key });
        console.log("response: ", response?.data?.data);
        setConvrsHis(response?.data?.data);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };

    fetchConvrsHis();
    fetchData();
  }, [state, convsnHistory, AgentById]);

  return (
    <>
      <div className="agent_head d_flx">
        <h2>Agent Details</h2>
        <Link to="/employees" className="add_btn">
          Back
        </Link>
      </div>
      <div className="d_flx align_top ">
        <div className="agent_fields w_49 bg_only">
          <div className="proifle_pic">
            <figure>
              <img
                className="pf_img"
                src={proPic ?? "/images/frame.png"}
                alt="user"
              />
              <input type="file" />
            </figure>
          </div>
          <div className="d_flx flx_input">
            <UserInput
              placeholder={fstName}
              icon="/images/user.svg"
              disabled={true}
            />
            <UserInput
              placeholder={lstName}
              icon="/images/user.svg"
              disabled={true}
            />
          </div>
          <UserInput
            type="text"
            placeholder={phone}
            icon="/images/mobile.svg"
            disabled={true}
          />
          <Email placeholder={email} disabled={true} />
          <UserInput
            placeholder={gender}
            icon="/images/user.svg"
            disabled={true}
          />
        </div>
        <div className="report_area w_49  bg_only">
          <h3>Report</h3>
          <AgntPieChartById id={state?.key} />
        </div>
      </div>
      <div className="table_box  bg_only">
        <h3>Conversation History</h3>
        <table>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Conversation Status</th>
              <th>Last Seen</th>
            </tr>
          </thead>
          <tbody>
            <Skeleton loading={load} active block={true}>
              {convrsHis?.length ? (
                convrsHis?.map((conHis) => (
                  <tr key={conHis?.id}>
                    <td>{conHis.user_name}</td>
                    <td>{conHis?.conversation_status}</td>
                    <td>{timesAgo(conHis?.created_at)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>
                    {" "}
                    <Empty description="No Past Conversation History" />
                  </td>
                </tr>
              )}
            </Skeleton>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default AgentDetail;
