/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {
  useState
} from "react";
import SaveBtn from "../../../components/savebtn";
import InputBox from "../../../components/inputbox";

const Appearance = () => {
  const [isOpen, SetIsOpen] = useState(false);
  return (
    <>
      <div className="stng_rhs">
        <div className="agent_head">
          <h2>API Keys</h2>
          <p>
            Configuring the security settings will add a layer of
            authentication.
          </p>
          <hr />
        </div>
        <div className="appearance_cntn">
          <p>
            Your secret API Keys are listed below. Please note that we do not
            display your secret API keys again after you generate them.
          </p>
          <p>
            Do not share your API key with others, or expose it in the browser
            or other client side code. In order to protect the security of your
            account, Tunixbot may also automatically rotate any API key that
            we've found has leaked publicly.
          </p>
          <div className="secret_table">
            <table>
              <thead>
                <tr>
                  <th>SECRET KEY</th>
                  <th>CREATED</th>
                  <th>LAST USED</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>EOHFOIDOGHJT6549+</td>
                  <td>Jan 2023</td>
                  <td>3 Sept 2023</td>
                  <td>
                    <i
                      className="fa-solid fa-angle-down"
                      onClick={() => SetIsOpen(!isOpen)}
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
            {isOpen && (
              <div className="pakage_device">
                <h3>Android</h3>
                <div className="input_grp">
                  <InputBox placeholder="Package Name *" />
                  <InputBox placeholder="SHA - 1 certificate fingerprint *" />
                </div>
                <h3>IOS</h3>
                <div className="input_grp">
                  <InputBox placeholder="Bundle ID *" />
                </div>
                <h3>Web</h3>
                <div className="input_grp">
                  <InputBox placeholder="URL *" />
                </div>
              </div>
            )}
          </div>
          <SaveBtn title="Create New secret key" />
          <h4>Default organization</h4>
          <p>
            If you belong to multiple organizations, This setting controls which
            organization is used by default when making requests with the API
            Keys above.
          </p>
          <div className="asign_box">
            <select name="box" id="bd">
              <option value="">Personal</option>
              <option value="">Personal 2</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};
export default Appearance;
