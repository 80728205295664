/* eslint-disable jsx-a11y/img-redundant-alt */
import { useChangeUserPassMutation } from "../services/api";
import ChangePass from "./changePassword";
import Modal from "react-modal";
import LabelInput from "./label";
import { Spin } from "antd";
import { isPasswordPattern } from "../utils/validations";
import React, { useState } from "react";
import { errorToast, successToast } from "../helpers/showToast";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ChangePassword = ({ setOpen, open }) => {
  const [changePass] = useChangeUserPassMutation();
  const [oldPass, setOldPass] = useState();
  const [newPass, setNewPass] = useState();
  const [cnfNewPass, setCnfNewPass] = useState();
  const [require, setRequire] = useState(false);
  const [load, setLoad] = useState(false);

  function closeModal() {
    setOpen(false);
  }

  const saveChangePass = async () => {
    if (!oldPass || !newPass || !cnfNewPass || !isPasswordPattern(newPass)) {
      console.log("Please enter all fields");
      setRequire(true);
      return;
    }
    if (newPass !== cnfNewPass) {
      console.log("Passwords do not match");
      setRequire(true);
      return;
    }
    let res;
    try {
      setLoad(true);
      const requestBody = {
        current_password: oldPass,
        new_password: newPass,
      };
      res = await changePass(requestBody);
      if (res?.data?.code === 200) {
        successToast(res?.data?.message);
        setOpen(false);
      } else {
        errorToast(res?.error?.data?.message);
      }
    } catch (error) {
      errorToast(res?.error?.data?.message);
      console.error("An error occurred:", error);
    } finally {
      setLoad(false);
      setRequire(false);
      setOldPass("");
      setNewPass("");
      setCnfNewPass("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveChangePass();
    }
  };

  return (
    <div>
      <Modal
        appElement={document.getElementById("root")}
        isOpen={open}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="change_password">
          <Spin spinning={load} size="medium">
            <div className="password_head">
              <h2>Change Password</h2>
            </div>
            <div>
              <LabelInput Name="Old Password :" />
              <ChangePass
                placeholder="Enter Your Old Password"
                type="password"
                value={oldPass}
                onChange={(e) => setOldPass(e.target.value)}
                require={require}
                val={1}
                onKeyDown={handleKeyPress}
              />
              <div>
                <LabelInput Name="New Password :" />
                <ChangePass
                  placeholder="Enter Your New Password"
                  type="password"
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                  require={require}
                  val={2}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <p>
                Your password must be at least 8 characters long, contain at
                least one number, one uppercase letter(A-Z) and one lowercase
                letter(a-z)
              </p>
              <div>
                <LabelInput Name="Re-type New Password :" />
                <ChangePass
                  placeholder="Enter your new password"
                  type="password"
                  value={cnfNewPass}
                  newPass={newPass}
                  onChange={(e) => setCnfNewPass(e.target.value)}
                  require={require}
                  val={3}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <div className="btn_grp">
                <button
                  type="button"
                  onClick={closeModal}
                  className="cancel_btn"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="save_btn"
                  onClick={saveChangePass}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </Spin>
        </div>
      </Modal>
    </div>
  );
};
export default ChangePassword;
