import "react-tabs/style/react-tabs.css";
import InputBox from "../../../components/inputbox";
import React, { useCallback, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Avatar, DatePicker, Empty, Spin } from "antd";
import {
  useAgntAssignListMutation,
  useAgntResolveListMutation,
  useCustomerFilterMutation,
  useAllChatsMutation,
} from "../../../services/agentconversation";
import SaveBtn from "../../../components/savebtn";

const ConversationLeftPanel = ({
  sendIdToParent,
  sendSelectedTab,
  EndChatResponse,
}) => {
  const [clicked, setClicked] = useState("");

  const handleClick = () => {
    clicked ? setClicked("") : setClicked("conv_leftPanel click-state");
  };

  const [assignList] = useAgntAssignListMutation();
  const [resolvedList] = useAgntResolveListMutation();
  const [allChats] = useAllChatsMutation();
  const [customerFilter] = useCustomerFilterMutation();
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState();
  const [tab, setTab] = useState();
  const [sltTab, setSltTab] = useState();
  const [assignlist, setAssignList] = useState();
  const [resolveList, setResovleList] = useState();
  const [alChat, setAlChat] = useState();
  const [selectAsgn, setSelectAsgn] = useState("");
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [totalCnt, setTotalCnt] = useState(10);
  const [require, setRequire] = useState(false);
  const [load, setLoad] = useState(false);

  const filter = async () => {
    if (!dateRange || !tab) {
      setRequire(true);
      console.log("enter condition");
      return;
    }
    try {
      setLoad(true);
      let body = {
        start_date: dateRange[0],
        end_date: dateRange[1],
        is_assigned: tab === "Open",
        is_resolved: tab === "Resolved",
      };
      const response = await customerFilter(body);
      const responseData = response?.data?.data;
      console.log("responseData: ", responseData);

      if (sltTab === 0) {
        setAssignList(responseData);
      } else if (sltTab === 1) {
        setResovleList(responseData);
      } else if (sltTab === 2) {
        setAlChat(responseData);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      clicked ? setClicked("") : setClicked("conv_leftPanel click-state");
      setLoad(false);
    }
    return true;
  };

  const fetchAssign = useCallback(
    async (search, loadMore) => {
      try {
        setLoad(true);
        let body = {
          page: 1,
          length: loadMore ?? 10,
          search_value: search ?? "",
        };
        const response = await assignList(body);
        const responseData = response?.data?.data;
        setAssignList(responseData);

        if (response?.data?.total_count > 10) {
          setShowLoadMore(true);
        }
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    },
    [assignList]
  );

  const fetchResolve = async (search, loadMore) => {
    try {
      setLoad(true);
      let body = {
        page: 1,
        length: loadMore ?? 10,
        search_value: search ?? "",
      };
      const response = await resolvedList(body);
      const responseData = response?.data?.data;
      setResovleList(responseData);
      if (response?.data?.total_count > 10) {
        setShowLoadMore(true);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  const fetchAllChat = async (search, loadMore) => {
    try {
      setLoad(true);
      let body = {
        page: 1,
        length: loadMore ?? 10,
        search_value: search ?? "",
      };
      const response = await allChats(body);
      const responseData = response?.data?.data;
      setAlChat(responseData);
      if (response?.data?.total_count > 10) {
        setShowLoadMore(true);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchAssign();
  }, [fetchAssign]);

  useEffect(() => {
    if (EndChatResponse === "unResolved") {
      fetchAssign();
      sendIdToParent("");
    }
  }, [EndChatResponse, fetchAssign, sendIdToParent]);

  const handleSelectAssign = (id, sesId, count) => {
    console.log("id: ", id);
    setSelectAsgn(id);
    sendIdToParent(sesId);
    if (count) {
      setTotalCnt(count);
    }
  };

  const handleToChange = (_, dateString) => {
    console.log("dateString: ", dateString);
    setDateRange(dateString);
  };

  const handleSearch = (e) => {
    const search = e.target.value;
    if (sltTab === 0) {
      fetchAssign(search);
    } else if (sltTab === 1) {
      fetchResolve(search);
    } else if (sltTab === 2) {
      fetchAllChat(search);
    } else {
      fetchAssign(search);
    }
  };

  const handleTabSelection = (key) => {
    sendSelectedTab(key);
    setSltTab(key);
    sendIdToParent(null);
    setSelectAsgn("");
    setTotalCnt(10);
    setShowLoadMore(false);
    switch (key) {
      case 0:
        fetchAssign();
        break;
      case 1:
        fetchResolve();
        break;
      case 2:
        fetchAllChat();
        break;
      default:
        fetchAllChat();
        break;
    }
  };

  const handleCheckedTab = (key) => {
    console.log("key: ", key);
    setTab(key);
    switch (key) {
      case "Open":
        setSltTab(0);
        break;
      case "Resolved":
        setSltTab(1);
        break;
      default:
        setSltTab(2);
    }
  };

  const handleLoadMore = () => {
    try {
      switch (sltTab) {
        case 0:
          fetchAssign("", totalCnt + 10);
          break;
        case 1:
          fetchResolve("", totalCnt + 10);
          break;
        case 2:
          fetchAllChat("", totalCnt + 10);
          break;
        default:
          fetchAssign("", totalCnt + 10);
          break;
      }
    } finally {
      setTotalCnt(totalCnt + 10);
    }
  };

  const capitalizeFirstLetter = (word) => {
    if (word.length === 0) {
      return "";
    }
    return word[0].toUpperCase();
  };

  return (
    // <div className="conv_leftPanel" { `base-state ${clicked && 'click-state'}`>
    <div className={clicked || "conv_leftPanel"}>
      <form className="serach_form">
        <InputBox
          type="search"
          placeholder="Search"
          onChange={handleSearch}
        ></InputBox>
        <figure className="filter_btn" onClick={handleClick}>
          <img className="flt_img" src="/images/icon_filter.svg" alt="" />
          <img className="crs_img" src="/images/close.svg" alt="" />
        </figure>
      </form>
      <div className="assigned_tab agent_tab">
        <Spin spinning={load} size="medium">
          <Tabs
            selectedIndex={sltTab || 0}
            onSelect={(index) => handleTabSelection(index)}
          >
            <TabList>
              <Tab>Assigned</Tab>
              <Tab>Resolved</Tab>
              <Tab>All</Tab>
            </TabList>
            <TabPanel>
              <ul className="user_conversation">
                {assignlist?.length ? (
                  assignlist?.map((assign) => (
                    <li
                      className={selectAsgn === assign?.id ? "active" : null}
                      key={assign?.id}
                      onClick={() =>
                        handleSelectAssign(
                          assign?.id,
                          assign?.session_id,
                          assign?.total_count
                        )
                      }
                    >
                      <figure className="filter_btn">
                        {assign?.user_pic?.media_file_url ? (
                          <img
                            src={assign?.user_pic?.media_file_url}
                            alt="user_pic"
                          />
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: "#ECF6FD",
                              color: "#414DE1",
                            }}
                          >
                            {capitalizeFirstLetter(assign?.user_name)}
                          </Avatar>
                        )}
                      </figure>
                      <div>
                        <h3>{assign?.user_name}</h3>
                        <p>Status has been changed this co. </p>
                      </div>
                    </li>
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </ul>
            </TabPanel>
            <TabPanel>
              <ul className="user_conversation">
                {resolveList?.length ? (
                  resolveList?.map((resolve) => (
                    <li
                      className={selectAsgn === resolve?.id ? "active" : null}
                      key={resolve?.id}
                      onClick={() =>
                        handleSelectAssign(
                          resolve?.id,
                          resolve?.session_id,
                          resolve?.total_count
                        )
                      }
                    >
                      <figure className="filter_btn">
                        {resolve?.user_pic?.media_file_url ? (
                          <img
                            src={resolve?.user_pic?.media_file_url}
                            alt="user_pic"
                          />
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: "#ECF6FD",
                              color: "#414DE1",
                            }}
                          >
                            {capitalizeFirstLetter(resolve?.user_name)}
                          </Avatar>
                        )}
                      </figure>
                      <div>
                        <h3>{resolve?.user_name}</h3>
                        <p>Status has been changed this co. </p>
                      </div>
                    </li>
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </ul>
            </TabPanel>
            <TabPanel>
              <ul className="user_conversation">
                {alChat?.length ? (
                  alChat?.map((alChatData) => (
                    <li
                      className={
                        selectAsgn === alChatData?.id ? "active" : null
                      }
                      key={alChatData?.id}
                      onClick={() =>
                        handleSelectAssign(
                          alChatData?.id,
                          alChatData?.session_id,
                          alChatData?.total_count
                        )
                      }
                    >
                      <figure className="filter_btn">
                        {alChatData?.user_pic?.media_file_url ? (
                          <img
                            src={alChatData?.user_pic?.media_file_url}
                            alt="user_pic"
                          />
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: "#ECF6FD",
                              color: "#414DE1",
                            }}
                          >
                            {capitalizeFirstLetter(alChatData?.user_name)}
                          </Avatar>
                        )}
                      </figure>
                      <div>
                        <h3>{alChatData?.user_name}</h3>
                        <p>Status has been changed this co. </p>
                      </div>
                    </li>
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </ul>
            </TabPanel>
          </Tabs>
        </Spin>
        {showLoadMore && (
          <p
            className="load_more"
            style={{ cursor: "pointer" }}
            onClick={handleLoadMore}
          >
            <i className="fa-solid fa-arrow-down"> </i> Load more
          </p>
        )}
      </div>
      <div className="filter_div">
        <div className="d_flx">
          <h2>Filter</h2>
          {/* <span>Clear All</span> */}
        </div>
        <div className="date_bx">
          <label>Date Range</label>
          <div className="input_box pd_input">
            <RangePicker onChange={handleToChange} />
          </div>
          {require && !dateRange && (
            <p style={{ color: "red", marginTop: "0", fontSize: "14px" }}>
              {" "}
              *Please provide date range
            </p>
          )}
        </div>
        <div className="box_flt">
          <div className="d_flx">
            <label>Status</label>
            <span onClick={() => handleCheckedTab("")}>Clear All</span>
          </div>
          <div className="checkbox_list d_flx">
            <div className="input_checkbox">
              <input
                type="checkbox"
                id="remember1"
                name="remember1"
                value={"Open"}
                onChange={(e) => handleCheckedTab(e.target.value)}
                checked={tab === "Open"}
              />
              <label htmlFor="remember1">Open</label>
            </div>
            <div className="input_checkbox">
              <input
                type="checkbox"
                id="remember2"
                name="remember2"
                value={"Resolved"}
                onChange={(e) => handleCheckedTab(e.target.value)}
                checked={tab === "Resolved"}
              />
              <label htmlFor="remember2">Resolved</label>
            </div>
            {require && !tab && (
              <p style={{ color: "red", marginTop: "0", fontSize: "14px" }}>
                {" "}
                *Please provide status
              </p>
            )}
          </div>
        </div>
        <SaveBtn title="Apply" onClick={filter} />
      </div>
    </div>
  );
};
export default ConversationLeftPanel;
