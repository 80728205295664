import React from "react";
import FlowDesigner from "../../components/flowdesigner";
import Layout from "../../layout";
import BuildView from "./features/buildview";

const BotBuilder = () => {
  return (
    <>
      <Layout>
          <FlowDesigner />
          <BuildView />
      </Layout>
    </>
  );
};
export default BotBuilder;
