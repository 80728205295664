import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer_box bg_dark ut_spc clr_white">
      <div className="conta_iner">
        <div className="d_flx align_top">
          <div className="ft_lgbx">
            <figure className="uhb_spc">
              <img src="./images/logo.svg" alt="logo" />
            </figure>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in
              iaculis ligula. Donec mollis, quam vel condimentum dignissim, eros
              erat ultrices tellus, eu porttitor lacus turpis eget ante.
              Praesent quis pharetra metus, ac sollicitudin urna. Mauris nisl
              nisi, vestibulum eleifend quam vitae, ullamcorper ullamcorper
              enim.
            </p>
          </div>
          <div className="ind_links">
            <h2 className="uhb_spc">Industries</h2>
            <ul>
              <li>
                <Link to="">Ecommerce</Link>
              </li>
              <li>
                <Link to="">Fintech</Link>
              </li>
              <li>
                <Link to="">Education</Link>
              </li>
              <li>
                <Link to="">Entertainment</Link>
              </li>
              <li>
                <Link to="">Telecom</Link>
              </li>
              <li>
                <Link to="">Travel & Hospitality</Link>
              </li>
              <li>
                <Link to=""></Link>
              </li>
            </ul>
          </div>
          <div className="ind_links">
            <h2 className="uhb_spc">Company</h2>
            <ul>
              <li>
                <Link to="">About Us</Link>
              </li>
              <li>
                <Link to="">Careers</Link>
              </li>
              <li>
                <Link to="">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="ind_links">
            <h2 className="uhb_spc">Social Media</h2>
            <ul>
              <li>
                <Link to="">YouTube</Link>
              </li>
              <li>
                <Link to="">Facebook</Link>
              </li>
              <li>
                <Link to="">LinkedIn</Link>
              </li>
              <li>
                <Link to="">Twitter</Link>
              </li>{" "}
              <li>
                <Link to="">Newsletter</Link>
              </li>
            </ul>
          </div>
        </div>
        <hr className="line" />
        <div className="copy_right">
          <p>© 2023 TunixBot, Inc. All rights reserved.</p>
          <ul className="d_flx ">
            <li>
              <Link to="">Privacy Policy</Link>
            </li>
            <li>
              <Link to="">Terms of Use</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
