import React from "react";
import { Link } from "react-router-dom";
const GoBack = ({ location, stateId, stateName, onClick }) => {
  return (
    <>
      <Link
        to={location}
        state={{ id: stateId, name: stateName }}
        className="go_back"
        onClick={onClick}
      >
        <i className="fa-solid fa-chevron-left"></i>
        <p>Go back to bot integrations</p>
      </Link>
    </>
  );
};
export default GoBack;
