import React, { useState } from "react";
import FlowDesigner from "../../components/flowdesigner";
import FlowStructure from "./features/flowstructure";
import Layout from "../../layout";
import BuildView from "../botbuilder/features/buildview";
import { useParams } from "react-router-dom";
import { IntentView } from "../../components/intentview";
import { SmallTalk } from "../../components/smalltalk";
import LiveChatView from "../../components/livechatview";

const DefaultFlow = () => {
  const { id } = useParams();

  const [liveMsg, setLiveMsg] = useState([
    {
      id: 47,
      type: 1,
      belongs_to: "newofd",
      text: "New weloclromffe",
      buttons: null,
    },
    {
      id: 48,
      type: 2,
      belongs_to: "newofd",
      text: null,
      buttons: [
        {
          id: 68,
          button_name: "defualt button",
          button_message: "old city",
          message_as_button: null,
          button_type: 1,
        },
        {
          id: 69,
          button_name: "link button",
          url: "",
          button_type: 2,
        },
        {
          id: 70,
          button_name: "submit",
          url: "nfkknk",
          button_message: "old car",
          message_as_button: null,
          button_type: 3,
        },
      ],
    },
    {
      id: 49,
      type: 1,
      belongs_to: "newofd",
      text: "New weloclromffe",
      buttons: null,
    },
    {
      id: 50,
      type: 2,
      belongs_to: "newofd",
      text: null,
      buttons: [
        {
          id: 71,
          button_name: "defualt button",
          button_message: "its been long day",
          message_as_button: null,
          button_type: 1,
        },
        {
          id: 72,
          button_name: "link button",
          url: "33333333333333332323",
          button_type: 2,
        },
        {
          id: 73,
          button_name: "submit",
          url: "23232323232323",
          button_message: "have a good day",
          message_as_button: null,
          button_type: 3,
        },
      ],
    },
  ]);

  const renderStructure = () => {
    switch (id) {
      case "buildView":
        return <BuildView setLiveMsg={setLiveMsg} liveMsg={liveMsg} />;
      case "intentView":
        return <IntentView />;
      case "smallTalk":
        return <SmallTalk />;
      default:
        return null;
    }
  };

  return (
    <>
      <Layout>
        <FlowDesigner id={id} />
        {id === "flowStructure" && <FlowStructure />}
        <div className="structure_area flx_strch">
          {renderStructure()}
          {id !== "flowStructure" && <LiveChatView liveMsg={liveMsg} />}
        </div>
      </Layout>
    </>
  );
};
export default DefaultFlow;
