import React from "react";
import { Link } from "react-router-dom";

const SettingSidebar = () => {
  return (
    <>
      <div className="stng_sidebar">
        <ul>
          <li className={window.location.pathname === "/thememanagement" ? "active" : ""}>
            <Link to="/thememanagement">Theme Management</Link>
          </li>
          {/* <li  className={window.location.pathname === "/apikey" ? "active" : ""}>
            <Link to="/apikey">API Keys</Link>
          </li> */}
          <li className={window.location.pathname === "/subscription" ? "active" : ""}>
            <Link to="/subscription">Subscription Management</Link>
          </li>
          <li className={window.location.pathname === "/servey" ? "active" : ""}>
            <Link to="/servey">CSAT (Customer Survey)</Link>
          </li>
          <li className={window.location.pathname === "/agentavailability" ? "active" : ""}>
            <Link to="/agentavailability">Agent Availability</Link>
          </li>
          <li className={window.location.pathname === "/fallbackemail" ? "active" : ""}>
            <Link to="/fallbackemail">Fallback Email</Link>
          </li>
          <li className={window.location.pathname === "/pushnotification" ? "active" : ""}>
            <Link to="/pushnotification">Push Notification Integration</Link>
          </li>
        </ul>
      </div>
    </>
  );
};
export default SettingSidebar;
