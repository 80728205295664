/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import AgentDetail from "./features/agentdetail";

const AgentView = () => {
  return (
    <>
      <Layout>
        <section className="main_area">
            <AgentDetail />
        </section>
      </Layout>
    </>
  );
};
export default AgentView;
