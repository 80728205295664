import React from "react";
import Layout from "../../layout";
import FlowDesigner from "../../components/flowdesigner";
import Training from "./features/training";

const BusinessTraining = () => {
  return (
    <>
      <Layout>
        <FlowDesigner />
        <Training />
      </Layout>
    </>
  );
};
export default BusinessTraining;
