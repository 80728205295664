import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Empty, Skeleton, Spin, Tooltip, Tour } from "antd";
import { storeData } from "../../../constants";
import { STORAGE_KEYS } from "../../../constants/storage";
import BotChat from "../../../components/botChat";
import PaginationBox from "../../../components/pagination";
import {
  useBotListMutation,
  useLazyOptionNoParentQuery,
} from "../../../services/managebot";
import SearchBox from "../../../components/search";
import { useLazyThemeByIdQuery } from "../../../services/settings";
import useAuth from "../../../hooks/useAuth";
import { errorToast, successToast } from "../../../helpers";
import { useBotHandleConvsnMutation } from "../../../services/createbot";
import EditDomain from "../../../components/editdomain";
import CopyToClipboard from "react-copy-to-clipboard";

const BotList = () => {
  const history = useNavigate();
  const [botList] = useBotListMutation();
  const [themeManage] = useLazyThemeByIdQuery();
  const [optionsNoParent] = useLazyOptionNoParentQuery();
  const [handleBotConvrsn] = useBotHandleConvsnMutation();
  const [botData, setBotData] = useState([]);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalCnt, setTotalCnt] = useState();
  const [current, setCurrent] = useState();
  const [botId, setBotId] = useState(null);
  const [botName, setBotName] = useState(null);
  const [botImgUrl, setBotImgUrl] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [domainData, setDomainData] = useState([]);
  const userData = useAuth();
  const ref1 = useRef(null);
  const [openTour, setOpenTour] = useState(false);
  const [botIndex, setBotIndex] = useState(null);

  const steps = [
    {
      title: "Costumize FAQ",
      description: "Set up the FAQ for your bot here.",
      target: () => ref1.current,
    },
  ];

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
      };
      const response = await botList(body);
      console.log("response: ", response);
      setBotData(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [botList, current]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleBuilder = async (production_api_key) => {
    try {
      setLoading(true);
      const response = await themeManage(userData?.business_name?.bussiness_id);
      const responseData = response?.data?.data;
      console.log("response: ", response);
      if (responseData?.online_status === null) {
        history("/thememanagement");
        errorToast("Theme is not configured. Please configure theme first.");
      } else {
        history(`/defaultflow/flowStructure`);
        storeData(STORAGE_KEYS.prod_bot_id, production_api_key);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBotChat = async (id, imgUrl, name, index) => {
    if (id) {
      try {
        const response = await optionsNoParent(id);
        if (response?.data?.data?.length === 0) {
          errorToast(
            "Bot FAQ is not configured. Please configure bot FAQ first."
          );
          setOpenTour(true);
          setBotIndex(index);
        } else {
          if (id && imgUrl && name) {
            setBotId(id);
            setBotImgUrl(imgUrl);
            setBotName(name);
            setOpen(true);
          }
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
  };

  const onChange = (page) => {
    setCurrent(page);
  };

  const handleSearch = async (e) => {
    const search = e.target.value;
    if (!search) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        search_value: search ?? "",
      };
      const response = await botList(body);
      console.log("response: ", response);
      setBotData(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  const handleBotConversation = async (botConvrs, id) => {
    try {
      let body = {
        bot_handle_conversation: !botConvrs,
      };
      const response = await handleBotConvrsn({ body, id });
      console.log("response: ", response);
      if (response?.data?.code === 200) {
        successToast(response?.data?.message);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleEdit = (data, id) => {
    setDomainData(data);
    setBotId(id);
    setOpenEdit(true);
  };

  const [isCopy, setIsCopy] = useState(false);

  const handleCody = () => {
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 400);
  };

  const closeTour = () => {
    setOpenTour(false);
    setBotIndex(null);
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="bg_bx">
          <div className="agent_head d_flx">
            <h2>Customer</h2>
            <div className="flt_search">
              <SearchBox onChange={handleSearch} />
            </div>
          </div>
          <div className="table_box">
            <table>
              <thead>
                <tr>
                  <th>Bot Name</th>
                  <th>Bot ID</th>
                  <th>Bot Platform</th>
                  <th>Production Key</th>
                  <th>Bot Conversation</th>
                  <th>Client Id</th>
                  <th>Action</th>
                </tr>
              </thead>
              <Skeleton loading={load} active avatar paragraph={{ rows: 1 }}>
                <tbody>
                  {botData?.length ? (
                    botData?.map((bot, index) => (
                      <tr key={bot?.id}>
                        <td>
                          <div className="ai_bot">
                            <figure>
                              <img
                                src={
                                  bot?.bot_image?.media_file_url ??
                                  "/images/bot.png"
                                }
                                alt="bot"
                              />
                            </figure>
                            <span>{bot?.bot_name}</span>
                          </div>
                        </td>
                        <td>{bot?.id}</td>
                        <td>{bot?.bot_platform}</td>
                        <td>
                          {bot?.production_api_key}
                          <CopyToClipboard
                            text={bot?.production_api_key}
                            onCopy={handleCody}
                          >
                            <Tooltip
                              title={isCopy ? "Copied" : "Copy"}
                              placement="top"
                              arrow
                              color="#414de1"
                            >
                              <i
                                className="fa-regular fa-copy"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "4px",
                                }}
                              ></i>
                            </Tooltip>
                          </CopyToClipboard>
                        </td>
                        <td>
                          <div className="csat_toggle">
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={bot?.bot_handle_conversation}
                                onChange={() =>
                                  handleBotConversation(
                                    bot?.bot_handle_conversation,
                                    bot?.id
                                  )
                                }
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </td>
                        <td>{bot?.client_id}</td>
                        <td>
                          <figure
                            className="edit_btn"
                            onClick={() =>
                              handleEdit(bot?.domain_setup, bot?.id)
                            }
                          >
                            <img src="./images/edit_new.svg" alt="edit" />
                          </figure>
                        </td>
                        <td>
                          <button
                            ref={index === botIndex ? ref1 : null}
                            type="button"
                            className="border_btn"
                            onClick={() =>
                              handleBuilder(bot?.production_api_key)
                            }
                          >
                            Go to bot builder
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="test_btn"
                            onClick={() =>
                              handleBotChat(
                                bot?.id,
                                bot?.bot_image?.media_file_url,
                                bot?.bot_name,
                                index
                              )
                            }
                          >
                            <i className="fa-solid fa-play"></i>
                            Test this bot
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : !load ? (
                    <div className="mange_bot_no_dt">
                      <Empty />
                    </div>
                  ) : null}
                </tbody>
              </Skeleton>
            </table>
            {botData?.length && totalCnt > 10 ? (
              <PaginationBox
                defaultCurrent={1}
                current={current}
                onChange={onChange}
                total={totalCnt}
              />
            ) : null}
          </div>
          <BotChat
            setOpen={setOpen}
            open={open}
            id={botId}
            botImgUrl={botImgUrl}
            botName={botName}
          />
          <EditDomain
            open={openEdit}
            setOpen={setOpenEdit}
            domainData={domainData}
            id={botId}
          />
          <Tour open={openTour} onClose={closeTour} steps={steps} />
        </div>
      </Spin>
    </>
  );
};
export default BotList;
