import React from "react";
import Layout from "../../layout";
import ProfileHead from "./features/profilehead";
import EditForm from "./features/editform";

function Contact() {
  return (
    <Layout>
      <section className="main_area">
        <ProfileHead/>
        <EditForm />
      </section>
    </Layout>
  );
}

export default Contact;
