import React from "react";
import Layout from "../../layout";
import FormSignup from "./features/form";

function SignUp() {
  return (
    <Layout>
      <FormSignup />
    </Layout>
  );
}

export default SignUp;
