import { Dropdown } from "antd";
import React from "react";

export const MessageView = (item, items, setLiveMsg, liveMsg) => {
  const handleWlcome = (e) => {
    const itemIndex = liveMsg.findIndex((msgItem) => msgItem.id === item.id);
    const updatedLiveMsg = [
      ...liveMsg.slice(0, itemIndex),
      {
        id: item.id,
        type: item.type,
        belongs_to: item.belongs_to,
        text: e,
        buttons: item.buttons,
      },
      ...liveMsg.slice(itemIndex + 1),
    ];
    setLiveMsg(updatedLiveMsg);
  };

  switch (item?.type) {
    case 1:
      return (
        <div className="bot_mssg">
          <figure>
            <img src="../images/dots.svg" alt="dots" />
          </figure>
          <div className="bt_txtarea">
            <label>Bot's Message</label>
            <textarea
              placeholder="Create a custom welcome message to greet your users"
              value={item?.text}
              onChange={(e) => handleWlcome(e.target.value)}
            />
            <figure>
              <img src="../images/usr.svg" alt="dots" />
            </figure>
          </div>
          <figure>
            <img src="../images/dlt.svg" alt="dots" />
          </figure>
        </div>
      );
    case 2:
      return (
        <div className="bot_mssg">
          <figure>
            <img src="../images/dots.svg" alt="dots" />
          </figure>
          <div className="bt_txtarea">
            <label>Buttons</label>
            <div className="btn_add">
              <div className="btn_field">
                {item?.buttons?.map((items) => buttonView(items))}
              </div>
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                placement="topRight"
              >
                <button type="button" className="buttons_add">
                  +
                </button>
              </Dropdown>
            </div>
          </div>
          <figure>
            <img src="../images/dlt.svg" alt="dots" />
          </figure>
        </div>
      );
    case 3:
      return (
        <div className="bot_mssg">
          <figure>
            <img src="../images/dots.svg" alt="dots" />
          </figure>
          <div className="bt_txtarea">
            <div className="upld_drag_img">
              <span>Drag & drop / Click here to upload image</span>
              <input type="file" />
            </div>
            <h3 className="text_center">OR</h3>
            <label>Image URL</label>
            <textarea placeholder="Please enter the image URL here" />
            <label>Caption (Optional)</label>
            <textarea placeholder="Please enter the image caption here" />
          </div>
          <figure>
            <img src="../images/dlt.svg" alt="dots" />
          </figure>
        </div>
      );
  }
};

const buttonView = (items) => {
  switch (items?.button_type) {
    case 1:
      return (
        <div className="btn_field_inpt">
          <figure className="mve_dots">
            <img src="../images/dots.svg" alt="dots" />
          </figure>
          <div className="btn_inpt">
            <figure>
              <img src="../images/bk_arrow.svg" alt="dots" />
            </figure>
            <input type="text" value={items?.button_name}></input>
          </div>
        </div>
      );
    case 2:
      return (
        <div className="btn_field_inpt">
          <figure className="mve_dots">
            <img src="../images/dots.svg" alt="dots" />
          </figure>
          <div className="btn_inpt">
            <figure>
              <img src="../images/prefix.svg" alt="dots" />
            </figure>
            <input type="text" value={items?.button_name}></input>
          </div>
        </div>
      );
    case 3:
      return (
        <div className="btn_field_inpt">
          <figure className="mve_dots">
            <img src="../images/dots.svg" alt="dots" />
          </figure>
          <div className="btn_inpt">
            <figure>
              <img src="../images/sb_btn.svg" alt="dots" />
            </figure>
            <input type="text" value={items?.button_name}></input>
          </div>
        </div>
      );
    default:
      return (
        <div className="btn_field_inpt">
          <figure className="mve_dots">
            <img src="../images/dots.svg" alt="dots" />
          </figure>
          <div className="btn_inpt">
            <figure>
              <img src="../images/prefix.svg" alt="dots" />
            </figure>
            <input type="text" value={items?.button_name}></input>
          </div>
        </div>
      );
  }
};
