import { Dropdown } from "antd";
import React from "react";
import DeleteModal from "./deletemodal";
import { errorToast, successToast } from "../helpers";
import {
  useBlockCustMutation,
  useDeleteCustMutation,
} from "../services/manageCust";

const CustomerDelBolc = ({ custId, first_name, fetchData, active }) => {
  const [DeleteCustomer] = useDeleteCustMutation();
  const [blockCustomer] = useBlockCustMutation();

  const handleDelete = async () => {
    try {
      const res = await DeleteCustomer(custId);
      if (res?.data?.code === 200) {
        successToast(res?.data?.message);
      } else {
        errorToast(res?.error?.data?.message);
      }
    } catch (error) {
      console.log("error: ", error);
    }
    fetchData();
  };

  const handleBlock = async () => {
    try {
      let body = {
        is_active: !active,
      };
      const res = await blockCustomer({ body, custId });
      if (res?.data?.code === 200) {
        successToast(res?.data?.message);
      } else {
        errorToast(res?.error?.data?.message);
      }
    } catch (error) {
      console.log("error: ", error);
    }
    fetchData();
  };

  const items = [
    {
      label: (
        <p onClick={() => DeleteModal(() => handleBlock(), "block", active)}>
          {active ? "Block" : "Unblock"}
        </p>
      ),
      key: "0",
    },
    {
      label: <p onClick={() => DeleteModal(() => handleDelete())}>Delete</p>,
      key: "1",
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
      <a onClick={(e) => e.preventDefault()} href="`#`">
        <button type="button" className="ellipes_box">
          <i className="fa-solid fa-ellipsis-vertical"></i>
        </button>
      </a>
    </Dropdown>
  );
};

export default CustomerDelBolc;
