import { useDispatch } from "react-redux";
import { removeData } from "../../constants";
import { useLogoutMutation } from "../../services/api";
import { useNavigate } from "react-router-dom";
import Notification from "../../components/notification";
import useAuth from "../../hooks/useAuth";
import { resetAuth } from "../../reducers/authSlice";
import { Dropdown, Switch, Tooltip, message } from "antd";
import { errorToast, successToast } from "../../helpers/showToast";
import { STORAGE_KEYS, getData, storeData } from "../../constants/storage";
import React, { useCallback, useEffect, useState } from "react";
import { usePutTestModeMutation } from "../../services/dashboard";
import {
  useLazyAgentStatusQuery,
  usePutAgentStatusMutation,
} from "../../services/agentdashboard";
import { reset } from "../../reducers/featureSlice";

const MainHeader = (props) => {
  const userRole = useAuth();
  const history = useNavigate();
  const [logout] = useLogoutMutation();
  const [getAgentStatus] = useLazyAgentStatusQuery();
  const [putTestMode] = usePutTestModeMutation();
  const [turnAwayMode] = usePutAgentStatusMutation();
  const [onStatus, setOnStatus] = useState();
  const [testMode, setTestMode] = useState(
    getData(STORAGE_KEYS?.environment) === "test" ||
      !getData(STORAGE_KEYS?.environment)
      ? false
      : true
  );
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const error = (text) => {
    console.log("error antd");
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  const handleLogout = async () => {
    try {
      const body = {
        device_type: "Web",
        device_token: getData(STORAGE_KEYS?.fcmToken),
      };

      if (userRole?.role === 1) {
        const res = await logout(body);
        console.log("Client logout");
        successToast(res?.data?.message);
        return;
      }

      if (userRole?.role === 2 && userRole?.agent_shift_timings) {
        const shiftTimings = userRole?.agent_shift_timings;
        const currentDay = new Date().toLocaleDateString("en-US", {
          weekday: "long",
        });
        const currentTime = new Date().toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        });

        if (
          currentDay.toUpperCase() === shiftTimings.day &&
          currentTime >= shiftTimings.end_time
        ) {
          const res = await logout(body);
          console.log("Time shift logout");
          if (res?.data?.code === 200) {
            successToast(res?.data?.message);
          } else if (res?.error?.data?.code === 400) {
            console.log("you have chat in queue");
            error(res?.error?.data?.message);
          }
          return;
        } else {
          error("Not within shift timings");
          console.log("Not within shift timings");
        }
      }
      console.log("Logout: Not applicable conditions");
    } catch (error) {
      console.error("Error during logout: ", error);
    } finally {
      handleLogoutCleanup();
    }
  };

  const handleLogoutCleanup = () => {
    history("/");
    removeData(STORAGE_KEYS?.token);
    removeData(STORAGE_KEYS?.userData);
    removeData(STORAGE_KEYS?.environment);
    resetAuth({});
  };

  const fetchTurnAwayMode = useCallback(async () => {
    let res;
    try {
      res = await getAgentStatus();
      console.log("res: ", res);
      setOnStatus(res?.data?.data?.is_agent_online);
    } catch (error) {
      console.log("error: ", error);
    }
  }, [getAgentStatus]);

  useEffect(() => {
    if (userRole?.role === 2) {
      fetchTurnAwayMode();
    }
  }, [fetchTurnAwayMode, userRole?.role]);

  useEffect(() => {
    const isNotMatchingPath =
      window.location.pathname !== "/bot_template" &&
      window.location.pathname !== "/botprofile" &&
      window.location.pathname !== "/handoff";

    if (isNotMatchingPath) {
      dispatch(reset({}));
    }
  }, [dispatch]);

  const handleTurnOnAwayMode = async (e) => {
    setOnStatus(e);
    try {
      const res = await turnAwayMode({ is_agent_online: e });
      console.log("res", res);
      const { code, message } = res?.data || {};
      console.log("code: ", code);
      if (code === 200) {
        successToast(message);
      } else {
        errorToast(res?.error?.data?.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleUserMode = async (isChecked) => {
    setTestMode(isChecked);
    try {
      const newEnvironment = isChecked ? "live" : "test";
      storeData(STORAGE_KEYS?.environment, newEnvironment);
      const fcmToken = getData(STORAGE_KEYS?.fcmToken);
      await putTestMode({
        device_token: fcmToken,
      });
      window.location.reload();
    } catch (error) {
      console.error("Error during environment switch:", error);
      errorToast("Failed to switch environment. Please try again.");
    }
  };

  const items = [
    {
      label: (
        <div className="drop_down">
          <p onClick={() => history("/editprofile")}>
            {" "}
            <img src="/images/edit-2.svg" alt="notification"></img>
            Edit Profile
          </p>
          {userRole?.role === 1 && (
            <p onClick={() => history("/contact")}>
              {" "}
              <img src="/images/help.svg" alt="notification"></img>
              Help
            </p>
          )}
          <p onClick={handleLogout}>
            {" "}
            <img src="/images/logout.svg" alt="notification"></img>
            Logout
          </p>
        </div>
      ),
      key: "0",
    },
  ];

  return (
    <>
      {contextHolder}
      <div className="main_header">
        <div
          className={
            window.location.pathname === "/employees" ? "top_title" : "d_none"
          }
        >
          Our Employee
        </div>
        <div
          className={
            props.activeCls ? "toggle_sidebar active" : "toggle_sidebar"
          }
          onClick={() => props.setActiveCls(!props.activeCls)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul>
          {userRole?.role === 1 ? (
            <li>
              <Tooltip
                title={
                  ((window.location.pathname === "/handoff" ||
                    window.location.pathname === "/botprofile" ||
                    window.location.pathname === "/success") &&
                    "You cannot switch during bot creation") ||
                  (window.location.pathname === "/defaultflow" &&
                    "You cannot switch during FAQ creation")
                }
                placement="bottom"
                arrow
                color="#f9f9f9"
                overlayInnerStyle={{ color: "#ff2d55" }}
              >
                <Switch
                  checkedChildren="Live"
                  unCheckedChildren="Test"
                  disabled={
                    window.location.pathname === "/handoff" ||
                    window.location.pathname === "/botprofile" ||
                    window.location.pathname === "/success" ||
                    window.location.pathname === "/defaultflow"
                  }
                  defaultChecked={testMode}
                  onChange={(e) => {
                    handleUserMode(e);
                  }}
                />
              </Tooltip>
            </li>
          ) : (
            userRole?.role === 2 && (
              <Switch
                checkedChildren="Online"
                unCheckedChildren="Offline"
                checked={onStatus}
                onChange={(e) => {
                  handleTurnOnAwayMode(e);
                }}
              />
            )
          )}
          <li>
            <div className="notification">
              <Notification />
            </div>
          </li>
          <li>
            <figure className="usr_img">
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                placement="bottomRight"
              >
                <img
                  src={
                    userRole?.profile_picture?.media_file_url
                      ? userRole?.profile_picture?.media_file_url
                      : "/images/frame.png"
                  }
                  alt="notification"
                ></img>
              </Dropdown>
            </figure>
          </li>
        </ul>
      </div>
    </>
  );
};
export default MainHeader;
