import React from "react";

const UserExperience = () => {
  return (
    <section className="usr_expsc">
      <div className="conta_iner">
        <div className="d_flx al_sc">
          <div
            className="abt_lhs w_48"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
          >
            <h2>
              In-app Chat
              <br /> Experiences Users <br />
              will Love
            </h2>
            <p className="gry_txt wd_60">
              An easy-to-use Chat API, native Chat SDKs, and a fully-managed
              chat platform on the backend means faster time-to-market.
            </p>
            <div className="lt_brdr">
              <h3>
                “Build modern chat and messaging experiences with offline
                messaging and analytics.”
              </h3>
            </div>
          </div>
          <div
            className="abt_rhs w_48"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
          >
            <figure>
              <img src="/images/user_experience.png" alt="hero"></img>
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserExperience;
