import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const convrsn = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    queueChat: builder.mutation({
      query: (body) => ({
        url: END_POINTS.queueChat,
        method: "POST",
        body,
      }),
    }),
    resolvedChat: builder.mutation({
      query: (body) => ({
        url: END_POINTS.resolvedChat,
        method: "POST",
        body,
      }),
    }),
    asignedChat: builder.mutation({
      query: (body) => ({
        url: END_POINTS.asignedChat,
        method: "POST",
        body,
      }),
    }),
    getagentList: builder.query({
      query: () => ({
        url: END_POINTS.getagentList,
        method: "GET",
      }),
    }),
    allConvrstn: builder.query({
      query: () => ({
        url: END_POINTS.allConvrstn,
        method: "GET",
      }),
    }),
    allCustChat: builder.query({
      query: () => ({
        url: END_POINTS.allCustChat,
        method: "GET",
      }),
    }),
    previousChat: builder.query({
      query: (sessionId) => ({
        url: `${END_POINTS.previousChat}${sessionId}/`,
        method: "GET",
      }),
    }),
    assignToAgnt: builder.mutation({
      query: ({ body, seId }) => ({
        url: `${END_POINTS.assignToAgnt}${seId}/`,
        method: "POST",
        body,
      }),
    }),
    filterCnvsCust: builder.mutation({
      query: (body) => ({
        url: END_POINTS.filterCnvsCust,
        method: "POST",
        body,
      }),
    }),
    reAssignList: builder.mutation({
      query: (body) => ({
        url: END_POINTS.reAssignList,
        method: "POST",
        body,
      }),
    }),
    reAssignAgent: builder.mutation({
      query: ({ body, seId }) => ({
        url: `${END_POINTS.reAssignAgent}${seId}/`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useAsignedChatMutation,
  useQueueChatMutation,
  useResolvedChatMutation,
  useAssignToAgntMutation,
  useFilterCnvsCustMutation,
  useLazyAllConvrstnQuery,
  useLazyAllCustChatQuery,
  useLazyGetagentListQuery,
  useLazyPreviousChatQuery,
  useReAssignListMutation,
  useReAssignAgentMutation,
} = convrsn;
