/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import BotSidebar from "../../components/botsidebar";
import GoBack from "../../components/goback";
import SuccessInfo from "./features/sucessinfo";

const SuccessIntegration = () => {
  return (
    <>
      <Layout>
        <section className="integration_main">
          {/* <BotSidebar /> */}
          <div className="bg_bx">
            {/* <GoBack location="/handoff" /> */}
            <SuccessInfo />
          </div>
        </section>
      </Layout>
    </>
  );
};
export default SuccessIntegration;
