/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useLazyDashCardDataQuery } from "../../../services/dashboard";

const Card = () => {
  const [cardData] = useLazyDashCardDataQuery();
  const [totalAgent, setTotalAgent] = useState();
  const [totalChat, setTotalChat] = useState();
  const [activeChat, setActiveChat] = useState();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoad(true);
        const response = await cardData();
        console.log("dashBoardresponse: ", response?.data?.data);
        const responseData = response?.data?.data;
        setTotalAgent(responseData?.total_agent);
        setTotalChat(responseData?.total_chat);
        setActiveChat(responseData?.active_chat);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };
    fetchData();
  }, [cardData]);

  return (
    <div className="d_flx">
      {/* <div
        className="shd_bx card_bx d_flx mt_0">
        <Progress
          type="circle"
          percent={trainBot}
          strokeWidth={14}
          strokeColor={"#414de1"}
          size={70}
        />
        <p>
          Complete your bot Training
        </p>
      </div> */}
      <div className="shd_bx  card_bx d_flx mt_0">
        <figure>
          <img src="/images/shield.svg" alt="image"></img>
        </figure>
        <div>
          <h2>{totalAgent}</h2>
          <p>Total Agent</p>
        </div>
      </div>
      <div className="shd_bx  card_bx d_flx mt_0">
        <figure>
          <img src="/images/communication.svg" alt="image"></img>
        </figure>
        <div>
          <h2>{totalChat}</h2>
          <p>Total Chat</p>
        </div>
      </div>
      <div className="shd_bx  card_bx d_flx mt_0">
        <figure>
          <img src="/images/email_n.svg" alt="image"></img>
        </figure>
        <div>
          <h2>{activeChat}</h2>
          <p>Active Chat</p>
        </div>
      </div>
    </div>
  );
};
export default Card;
