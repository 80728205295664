/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import CustomerList from "./features/customerlist";

const Customer = () => {
  return (
    <>
      <Layout>
        <section className="main_area">
          
          <CustomerList />
        </section>
      </Layout>
    </>
  );
};
export default Customer;
