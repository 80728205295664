import React from "react";
import Layout from "../../layout";
import CustomQuery from "./features/customquery";
import FlowDesigner from "../../components/flowdesigner";

const DomainSetup = () => {
  return (
    <>
      <Layout>
        <FlowDesigner />
        <CustomQuery />
      </Layout>
    </>
  );
};
export default DomainSetup;
