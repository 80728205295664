import { Link } from "react-router-dom";
import SearchBox from "../../../components/search";
import Filter from "../../../components/filter";
import PaginationBox from "../../../components/pagination";
import { errorToast, successToast } from "../../../helpers/showToast";
import DeleteModal from "../../../components/deletemodal";
import React, { useCallback, useEffect, useState } from "react";
import { Empty, Skeleton } from "antd";
import {
  useCompanyAgntMutation,
  useDeleteAgentMutation,
  useFilterAgentMutation,
} from "../../../services/agent";
import { timesAgo } from "../../../utils/timeago";

const EmployeeList = () => {
  const [companyAgent] = useCompanyAgntMutation();
  const [DeleteAgent] = useDeleteAgentMutation();
  const [filterAgent] = useFilterAgentMutation();
  const [agentData, setAgentData] = useState([]);
  const [load, setLoad] = useState(false);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [totalCnt, setTotalCnt] = useState();
  const [current, setCurrent] = useState();

  const onChange = (page) => {
    setCurrent(page);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
      };
      const response = await companyAgent(body);
      setAgentData(response?.data?.data);

      setTotalCnt(response.data.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [companyAgent, current]);

  useEffect(() => {
    if (!from || !to) {
      fetchData();
    }
  }, [fetchData, from, to]);

  const handleDelete = async (id) => {
    try {
      const res = await DeleteAgent(id);
      if (res?.data?.code === 200) {
        successToast(res?.data?.message);
      } else {
        errorToast(res?.error?.data?.message);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      fetchData();
    }
  };

  const filter = async () => {
    let response;
    try {
      setLoad(true);
      let body = {
        start_date: from,
        end_date: to,
      };
      if (from && to) {
        response = await filterAgent(body);
        setAgentData(response.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  const handleFromChange = (e) => {
    setFrom(e);
  };

  const handleToChange = (e) => {
    setTo(e);
  };

  const handleSearch = async (e) => {
    const search = e.target.value;
    if (!search) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        search_value: search ?? "",
      };
      const response = await companyAgent(body);
      setAgentData(response?.data?.data);
      setTotalCnt(response.data.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <div className="bg_bx">
        <div className="agent_head d_flx">
          <h2>Agent List</h2>
          <div className="d_flx">
            <Link to="/addagent" className="add_btn">
              Add New Agent
            </Link>
            <div className="flt_search">
              <SearchBox onChange={handleSearch} />
              <Filter
                onFromChange={handleFromChange}
                onToChange={handleToChange}
                onClick={filter}
              />
            </div>
          </div>
        </div>
        <div className="table_box">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Latest Conversation</th>
                <th>Action</th>
              </tr>
            </thead>
            <Skeleton loading={load} active block={true}>
              {agentData?.length ? (
                agentData?.map((agent) => (
                  <tbody key={agent.id}>
                    <tr>
                      <td>{agent.first_name}</td>
                      <td>{agent.email}</td>
                      <td>{agent.phone_no}</td>
                      <td className="text_blue">
                        {agent?.latest_conversation
                          ? timesAgo(agent?.latest_conversation)
                          : "N/A"}
                      </td>
                      <td>
                        <ul className="tbl_icon">
                          <li>
                            <Link to="/agentview" state={{ key: agent?.id }}>
                              <figure>
                                <img src="/images/eye_n.svg" alt="eye" />
                              </figure>
                            </Link>
                          </li>
                          <li>
                            <Link to="/addagent" state={{ key: agent?.id }}>
                              <figure>
                                <img src="/images/edit.svg" alt="eye" />
                              </figure>
                            </Link>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                DeleteModal(() => handleDelete(agent?.id))
                              }
                            >
                              <figure>
                                <img src="/images/delete.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                ))
              ) : !load ? (
                <div className="no_dt">
                  <Empty />
                </div>
              ) : null}
            </Skeleton>
          </table>
        </div>
        {agentData?.length && totalCnt > 10 ? (
          <PaginationBox
            defaultCurrent={1}
            current={current}
            onChange={onChange}
            total={totalCnt}
          />
        ) : null}
      </div>
    </>
  );
};
export default EmployeeList;
