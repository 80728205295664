import { Spin } from "antd";
import { useCrteBotMutation } from "../../../services/createbot";
import SaveBtn from "../../../components/savebtn";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../../helpers/showToast";
import { useDispatch } from "react-redux";
import { setStepperData } from "../../../reducers/featureSlice";

const Info = () => {
  let history = useNavigate();
  const [createBot] = useCrteBotMutation();
  const [load, setLoad] = useState(false);
  const location = useLocation();
  const { state } = location;
  const [handOff, setHandOff] = useState(false);
  const dispatch = useDispatch();
  const SuccessBot = async () => {
    try {
      setLoad(true);
      let body = {
        bot_name: state?.bName,
        bot_avatar: state?.bAvatar,
        bot_language: 1,
        enable_automatic_bot: handOff,
        bot_template: state?.bTemp,
        domain_setup: state?.handOffDomain,
      };
      const response = await createBot(body);
      const responseData = response?.data?.data;
      console.log("response: ", response);
      if (response?.data?.code === 201) {
        successToast(response?.data?.message);
        history("/success", { state: { data: responseData } });
        dispatch(setStepperData({ stepperData: "humanHandoff" }));
      } else {
        errorToast(response?.error?.data?.message);
      }
    } catch (error) {
      errorToast(error);
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };
  return (
    <>
      <div className="bot_setup">
        <Spin spinning={load} size="medium">
          <h2>Automatic bot to human handoff:</h2>
          <p>
            Sandeep can handoff the conversation to a human team member when
            <br /> it is unable to recognize any user query.Once the handoff is
            done, the bot will stop replying.
          </p>
          <div className="enable_handoff">
            <h3>Do you want to enable automatic bot to human handoff?</h3>
            <div className="input_radio">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="HTML"
                className="input-radio"
                onChange={() => setHandOff(true)}
              />
              <label htmlFor="html">Yes Enable this feature</label>
            </div>
            <div className="input_radio">
              <input
                defaultChecked={true}
                type="radio"
                id="html1"
                name="fav_language"
                value="HTML"
                className="input-radio"
                onChange={() => setHandOff(false)}
              />
              <label htmlFor="html1">Disable this feature (Default)</label>
            </div>
            <SaveBtn title="Submit" onClick={SuccessBot} />
          </div>
        </Spin>
      </div>
    </>
  );
};
export default Info;
