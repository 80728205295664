import React from "react";

const Immersive = () => {
  return (
    <div className="conta_iner">
      <div className="d_flx al_sc clm_rvrs" data-aos="zoom-out-up">
        <div className="abt_lhs w_48">
          <figure>
            <img src="/images/immersive.png" alt="hero"></img>
          </figure>
        </div>
        <div className="abt_lhs w_48">
          <h2>
            Immersive Agent and Bot
            <br />
            Experiences
          </h2>
          <p className="gry_txt">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in
            iaculis ligula. Donec mollis, quam vel condimentum dignissim, eros
            erat ultrices tellus, eu porttitor lacus turpis eget ante.
          </p>
          <ul className="blue_list">
            <li>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in
              iaculis ligula. Donec mollis, quam vel condimentum dignissim, eros
              erat ultrices tellus, eu porttitor lacus turpis eget ante.
            </li>
            <li>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in
              iaculis ligula. Donec mollis, quam vel condimentum dignissim, eros
              erat ultrices tellus, eu porttitor lacus turpis eget ante.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Immersive;
