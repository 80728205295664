import { Line } from "react-chartjs-2";
import { Spin } from "antd";
import { useLazyAvtiveChatQuery } from "../../../services/dashboard";
import React, {
  useEffect,
  useState
} from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


export function ActiveChat() {

  const labels = [];

  const options = {
    responsive: true,
    plugins: {
      legend: false,
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    }
  };
  const [activeChat, setActiveChat] = useState([]);
  const [load, setLoad] = useState(false)
  const [DashActiveChat] = useLazyAvtiveChatQuery();

  useEffect(() => {
    const totalClient = async () => {
      let res
      try {
        setLoad(true)
        res = await DashActiveChat()
        console.log('res: ', res?.data);
        setActiveChat(res?.data?.data)
      } catch (error) {
        console.log('error: ', error);
      } finally {
        setLoad(false)
      }
    }
    totalClient();
  }, [DashActiveChat])

  const data = {
    labels,
    datasets: [
      {
        label: 'No of Active Chat',
        data: activeChat,
        borderColor: "#414de1",
        borderWidth: '4',
        pointRadius: '0',
        fill: false,
        lineTension: 0.31645,
        backgroundColor: "#414de1",
        borderCapStyle: 'butt',
        borderJoinStyle: 'miter',
        pointBorderColor: '#fff',
        pointBackgroundColor: '#28a745',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#ffcc99',
        pointHoverBorderColor: '#f5efef',
        pointHitRadius: 50,
      },
    ],
  };

  return (
    <>
      <div className="shd_bx chat_bot w_49">
        <h3>Active Chat Per Hours</h3>
        <Spin spinning={load} size="medium">
          <Line options={options} data={data} />
        </Spin>
      </div>
    </>
  );
}
