import UserInput from "../../../components/userinput";
import Email from "../../../components/email";
import SaveBtn from "../../../components/savebtn";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UploadMedia } from "../../../services/uploadmedia";
import { errorToast, successToast } from "../../../helpers/showToast";
import {
  isAlphabete,
  isEmail,
  validMobileNo,
} from "../../../utils/validations";
import {
  useAgentAddMutation,
  useEditAgentMutation,
  useLazyAgentByIdQuery,
} from "../../../services/agent";
import VerifyModal from "../../../components/modal";

const Add = () => {
  const history = useNavigate();
  const [addAgent] = useAgentAddMutation();
  const [EditAgent] = useEditAgentMutation();
  const [fstName, setFstName] = useState();
  const [lstName, setLstName] = useState();
  const [phNumber, setPhNumber] = useState();
  const [AgentById] = useLazyAgentByIdQuery();
  const [email, setEmail] = useState();
  const [gender, setGender] = useState();
  const [require, setRequire] = useState(false);
  const [profilePic, SetProfilePic] = useState(null);
  const [profileImg, setProfileImg] = useState("./images/profile_dummy.svg");
  const location = useLocation();
  const { state } = location;
  const [load, setLoad] = useState(false);
  const [mediaId, setMediaId] = useState();
  const [tit, setTit] = useState("");
  const [open, setOpen] = useState(false);

  // handle photo file
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImg(URL.createObjectURL(file));
      SetProfilePic(file);
    }
  };

  // handle fetch user data for edit profile
  const fetchData = async () => {
    let response;
    try {
      setLoad(true);
      response = await AgentById(state.key);
      console.log("getAgentRes: ", response);
      const responseData = response.data.data;
      setFstName(responseData.first_name);
      setLstName(responseData.last_name);
      setEmail(responseData.email);
      setPhNumber(responseData.phone_no);
      setGender(responseData.gender);
      setProfileImg(responseData.profile_picture.media_file_url);
      if (!profilePic) {
        setMediaId(responseData.profile_picture.id);
      }
    } catch (error) {
      console.log("error: ", error);
      errorToast(response.error.data.message);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (state?.key) {
      setTit("Edit Agent");
      fetchData();
    } else {
      setTit("Add Agent");
    }
  }, [state]);

  // handle save button
  const saveAgent = async () => {
    if (!state?.key) {
      // if there is not any key then it will work to add agent
      if (
        !fstName ||
        !lstName ||
        !phNumber ||
        !email ||
        !isEmail(email) ||
        !validMobileNo(phNumber)
      ) {
        console.log("enter  value");
        setRequire(true);
        return;
      }
      let response;
      try {
        setLoad(true);
        let mediaResponse;
        let proMedia;
        if (profilePic) {
          mediaResponse = await UploadMedia(profilePic);
          proMedia = mediaResponse.data.find((media) => media.id);
        }
        const body = {
          first_name: fstName,
          email: email,
          last_name: lstName,
          profile_picture: profilePic ? proMedia.id : null,
          phone_no: phNumber,
          gender: gender,
        };
        response = await addAgent(body);
        console.log("response: ", response);
        if (response?.data?.code === 201) {
          successToast(response?.data?.message);
          setOpen(true);
        } else {
          errorToast(response?.error?.data?.message);
        }
      } catch (error) {
        console.log("Error:::::::::::", error);
        errorToast(response.error.data.message);
      } finally {
        setRequire(false);
        setLoad(false);
      }
    } else {
      // it will work to edit agent
      let response;
      try {
        setLoad(true);
        let mediaResponse;
        let proMedia;
        if (profilePic) {
          mediaResponse = await UploadMedia(profilePic);
          proMedia = await mediaResponse.data.find((media) => media.id);
        }
        const body = {
          first_name: fstName,
          email: email,
          last_name: lstName,
          profile_picture: profilePic ? proMedia.id : mediaId,
          phone_no: phNumber,
          gender: gender,
        };
        const id = state?.key;
        response = await EditAgent({ body, id });
        successToast(response.data.message);
        history("/employees");
      } catch (error) {
        errorToast(response.error.data.message);
        console.log("Error::::::::::::::", error);
      } finally {
        setLoad(false);
      }
    }
  };

  const phoneNumber = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    setPhNumber(numericValue);
  };

  const handleFname = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setFstName(input);
    }
  };

  const handleLname = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setLstName(input);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveAgent();
    }
  };

  return (
    <>
      <div className="bg_bx">
        <Spin spinning={load} size="medium">
          <div className="agent_head d_flx">
            <h2>{tit}</h2>
            <Link to="/employees" className="add_btn">
              Back
            </Link>
          </div>
          <div className="agent_fields w_48">
            <div className="proifle_pic">
              <figure>
                <img src={profileImg ?? "/images/frame.png"} alt="user" />
                <input type="file" onChange={handleFileUpload} />
              </figure>
            </div>
            <div className="d_flx flx_input">
              <UserInput
                placeholder="First Name"
                icon="/images/user.svg"
                value={fstName}
                onChange={handleFname}
                require={require}
                onKeyDown={handleKeyPress}
              />
              <UserInput
                placeholder="Last Name"
                icon="/images/user.svg"
                value={lstName}
                onChange={handleLname}
                require={require}
                onKeyDown={handleKeyPress}
              />
            </div>
            <UserInput
              type="text"
              placeholder="Phone Number"
              icon="/images/mobile.svg"
              value={phNumber}
              onChange={phoneNumber}
              require={require}
              onKeyDown={handleKeyPress}
            />
            <Email
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              require={require}
              disabled={state?.key}
              onKeyDown={handleKeyPress}
            />
            <div className="gernder_box">
              <span>Gender</span>
              <div className="input_radio">
                <input
                  type="radio"
                  id="html1"
                  name="fav_language"
                  value={gender}
                  className="input-radio"
                  checked={gender === "male"}
                  onChange={() => setGender("male")}
                  onKeyDown={handleKeyPress}
                />
                <label htmlFor="html1">Male</label>
              </div>
              <div className="input_radio">
                <input
                  type="radio"
                  id="html2"
                  name="fav_language"
                  value={gender}
                  className="input-radio"
                  checked={gender === "female"}
                  onChange={() => setGender("female")}
                  onKeyDown={handleKeyPress}
                />
                <label htmlFor="html2">Female</label>
              </div>
            </div>
            {require
              ? !gender && (
                  <p
                    style={{
                      color: "red",
                      fontSize: 15,
                      paddingBottom: 2,
                      margin: 0,
                    }}
                  >
                    Please select gender
                  </p>
                )
              : null}
            <SaveBtn title="Save" onClick={saveAgent} />
          </div>
        </Spin>
      </div>
      <VerifyModal
        setOpen={setOpen}
        open={open}
        email={email}
        data={"signup"}
        page={"add_agent"}
      />
    </>
  );
};
export default Add;
