import moment from 'moment';

export function timesAgo(createdAt) {
    const createdMoment = moment(createdAt);
    const currentMoment = moment();
    const duration = moment.duration(currentMoment.diff(createdMoment));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    let timeAgoStr = '';
    if (days > 0) {
        timeAgoStr = `${days} days ago`;
    } else if (hours > 0) {
        timeAgoStr = `${hours} hours ago`;
    } else if (minutes > 0) {
        timeAgoStr = `${minutes} minutes ago`;
    } else {
        timeAgoStr = 'Less than a minute ago';
    }

    return timeAgoStr || 'Unknown Time';
}

function formatTime(createdAt) {
    const createdMoment = moment(createdAt);
    return createdMoment.format('MMM D, hh:mm A');
}

export default formatTime;