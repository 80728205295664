import LabelInput from "../../../components/label";
import InputBox from "../../../components/inputbox";
import ChangePassword from "../../../components/changepasswordmodal";
import SaveBtn from "../../../components/savebtn";
import { Spin } from "antd";
import useAuth from "../../../hooks/useAuth";
import { setCredentials } from "../../../reducers/authSlice";
import { useDispatch } from "react-redux";
import { STORAGE_KEYS, storeData } from "../../../constants/storage";
import React, { useCallback, useEffect, useState } from "react";
import { isAlphabete, validMobileNo } from "../../../utils/validations";
import {
  useEditProfileMutation,
  useGetUserDetailsMutation,
} from "../../../services/api";
import { UploadMedia } from "../../../services/uploadmedia";
import { errorToast, successToast } from "../../../helpers/showToast";

const EditForm = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [getUser] = useGetUserDetailsMutation();
  const [EditProfile] = useEditProfileMutation();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [load, setLoad] = useState(false);
  const [profilePic, SetProfilePic] = useState(null);
  const [profileImg, setProfileImg] = useState(null);
  const [required, setRequire] = useState(false);
  const userData = useAuth();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      SetProfilePic(file);
      setProfileImg(URL.createObjectURL(file));
    }
  };

  const saveProfile = async () => {
    if (!fname || !lname || !email) {
      setRequire(true);
      return;
    }
    if (phone) {
      if (!validMobileNo(phone)) {
        return;
      }
    }
    let mediaResponse;
    let proMedia;
    let response;
    try {
      setLoad(true);
      if (profilePic) {
        mediaResponse = await UploadMedia(profilePic);
        proMedia = await mediaResponse.data.find((media) => media.id);
      }
      let body = {
        email: email,
        first_name: fname,
        last_name: lname,
        phone_no: phone,
        profile_picture: profilePic ? proMedia?.id : null,
      };
      response = await EditProfile(body);
      if (response?.data?.code === 200) {
        const userData = await getUser();
        console.log("userData: ", userData?.data?.data);
        storeData(STORAGE_KEYS.userData, userData?.data?.data);
        dispatch(
          setCredentials({
            user: userData?.data?.data,
          })
        );
        successToast(response?.data?.message);
      }
    } catch (error) {
      errorToast(response.error.data.message);
      console.log("error: ", error);
    } finally {
      setLoad(false);
      setRequire(false);
      fetchData();
    }
  };

  const fetchData = useCallback(() => {
    try {
      setLoad(true);
      setFname(userData?.first_name);
      setLname(userData?.last_name);
      setEmail(userData?.email);
      setPhone(userData?.phone_no);
      setProfileImg(userData?.profile_picture?.media_file_url);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [userData]);

  const phNumber = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    setPhone(numericValue);
  };

  const handleFname = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setFname(input);
    }
  };

  const handleLname = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setLname(input);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveProfile();
    }
  };

  return (
    <>
      <Spin spinning={load} size={"medium"}>
        <div className="d_flx prf_field">
          <div className="fld_lhs">
            <div>
              <LabelInput Name="First Name :" />
              <InputBox
                placeholder="Enter your first name"
                type="text"
                value={fname}
                onChange={handleFname}
                require={required}
                reqValue="your first name"
                onkeyDown={handleKeyPress}
              />
            </div>
            <div>
              <LabelInput Name="Last Name :" />
              <InputBox
                placeholder="Enter your last name"
                type="text"
                value={lname}
                onChange={handleLname}
                require={required}
                reqValue="your last name"
                onkeyDown={handleKeyPress}
              />
            </div>
            <div>
              <LabelInput Name="Email :" />
              <InputBox
                placeholder="Enter your email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                require={required}
                reqValue="your email"
                onkeyDown={handleKeyPress}
                disabled={true}
              />
            </div>
            <div>
              <LabelInput Name="Phone Number (optional) :" />
              <InputBox
                placeholder="Enter your contact number"
                type="text"
                value={phone}
                onChange={phNumber}
                onkeyDown={handleKeyPress}
              />
            </div>
            <SaveBtn title="Save Changes" onClick={saveProfile} />
            <div className="d_flx chng_pswd" onClick={() => setOpen(true)}>
              <div>
                <h2>Change Password</h2>
                <p>Update your password</p>
              </div>
              <i className="fa-solid fa-chevron-right"></i>
            </div>
          </div>
          <div className="fld_rhs text_center">
            <figure>
              <img src={profileImg ?? "/images/frame.png"} alt="profile" />
              <InputBox type="file" onChange={handleFileUpload} />
            </figure>
          </div>
        </div>
        <ChangePassword setOpen={setOpen} open={open} />
      </Spin>
    </>
  );
};
export default EditForm;
