/* eslint-disable jsx-a11y/img-redundant-alt */
import { successToast } from "../../../helpers/showToast";
import dayjs from "dayjs";
import { Spin, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import {
  useAnonymousUserMutation,
  useEditAgntTimingMutation,
  useLazyGetAgntTimingQuery,
  useLazyThemeByIdQuery,
  useSendAwaymessageMutation,
} from "../../../services/settings";
import useAuth from "../../../hooks/useAuth";

const AwayMessage = () => {
  const [load, setLoad] = useState(false);
  const [getCsat] = useLazyThemeByIdQuery();
  const [awayMessage] = useSendAwaymessageMutation();
  const [getTiming] = useLazyGetAgntTimingQuery();
  const [agentTiming] = useEditAgntTimingMutation();
  const [anonymousUser] = useAnonymousUserMutation();
  const [away, setAway] = useState(0);
  const [emailCmt, setEmailCmt] = useState("");
  const userData = useAuth();
  const [reqAway, setReqAway] = useState(false);
  const [timeData, setTimeData] = useState([
    {
      id: 1,
      day: "MONDAY",
      start_time: "00:00:00",
      end_time: "00:00:00",
    },
    {
      id: 2,
      day: "TUESDAY",
      start_time: "00:00:00",
      end_time: "00:00:00",
    },
    {
      id: 3,
      day: "WEDNESDAY",
      start_time: "00:00:00",
      end_time: "00:00:00",
    },
    {
      id: 4,
      day: "THURSDAY",
      start_time: "00:00:00",
      end_time: "00:00:00",
    },
    {
      id: 5,
      day: "FRIDAY",
      start_time: "00:00:00",
      end_time: "00:00:00",
    },
    {
      id: 6,
      day: "SATURDAY",
      start_time: "00:00:00",
      end_time: "00:00:00",
    },
    {
      id: 7,
      day: "SUNDAY",
      start_time: "00:00:00",
      end_time: "00:00:00",
    },
  ]);

  const saveData = async () => {
    const companyId = await userData?.business_name?.bussiness_id;
    if (!emailCmt) {
      setReqAway(true);
      return;
    }
    try {
      setLoad(true);
      let body = {
        away_Message_for_anonymous_users: emailCmt,
      };
      const response = await anonymousUser({ body, id: companyId });
      console.log("response: ", response);
      successToast(response?.data?.message);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
      setReqAway(false);
    }
  };

  const SaveAwayData = async () => {
    const companyId = await userData?.business_name?.bussiness_id;
    const valueCsat = !away;
    try {
      let body = {
        show_away_message_to_users: valueCsat,
      };
      const response = await awayMessage({ body, id: companyId });
      console.log("response: ", response);
      successToast(response?.data?.message);
      setEmailCmt("");
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const saveTimeData = async () => {
    try {
      setLoad(true);
      const editTiming = await agentTiming(timeData);
      console.log("editTiming: ", editTiming);
      successToast(editTiming?.data?.message);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  const handleRolePermissionChange = (day, type, _, timeString) => {
    setTimeData((prevTimeData) => {
      return prevTimeData.map((data) => {
        if (data.day === day) {
          return {
            ...data,
            [type]: timeString,
          };
        }
        return data;
      });
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoad(true);
        const response = await getCsat(userData?.business_name?.bussiness_id);
        const responseData = response?.data?.data;
        setAway(responseData?.show_away_message_to_users);
        setEmailCmt(responseData?.away_Message_for_anonymous_users);
        const agntTiming = await getTiming();
        const agentTimingData = agntTiming?.data?.data;

        const updatedTimeData = timeData.map((data) => {
          const matchingData = agentTimingData.find(
            (agentData) => agentData.day === data.day
          );
          if (matchingData) {
            if (
              matchingData.start_time !== data.start_time ||
              matchingData.end_time !== data.end_time
            ) {
              return matchingData;
            }
          }
          return data;
        });
        setTimeData(updatedTimeData);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };
    fetchData();
  }, [getCsat, getTiming]);

  const daySlicer = (val) => {
    let day = val.slice(0, 3);
    return day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();
  };

  return (
    <>
      <div className="stng_rhs">
        <Spin spinning={load} size="medium">
          <div className="agent_head">
            <h2>Away Message</h2>
            <p>
              The away message will be shown to users when the entire team is
              away or offline.
            </p>
            <hr />
          </div>
          <div className="csat_toggle d_flx">
            <h3>Show away message to users</h3>
            <label className="switch">
              <input
                type="checkbox"
                checked={away}
                onChange={(e) => setAway(e.target.checked)}
                onClick={SaveAwayData}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <hr className="mt_30" />
          <div className="anony_bx">
            <h4>Away Message for anonymous users</h4>
            <div className="check_msg">
              <input type="checkbox" id="bx1" name="bx1" value="" />
              <label htmlFor="bx1">Collect email ID from user</label>
            </div>
            <div>
              <textarea
                className="ex_helo"
                placeholder="Ex : Hello, Please leave 
                your email id and your message and 
                we will get back to you as soon as possible."
                value={emailCmt}
                onChange={(e) => setEmailCmt(e.target.value)}
              />
              {reqAway && !emailCmt && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  Enter away message for anonymous users
                </p>
              )}
            </div>
            <div className="btn_grp">
              <button type="button" className="save_btn" onClick={saveData}>
                Save
              </button>
              <button
                type="button"
                onClick={() => setEmailCmt("")}
                className="cancel_btn"
              >
                Discard
              </button>
            </div>
          </div>
          <div className="week_bx">
            <div className="csat_toggle">
              <h3>Set Shift Timing for agents</h3>
            </div>
            {timeData?.map((data) => (
              <div className="week_day" key={data?.id}>
                <p>{daySlicer(data?.day)}</p>
                <div className="week_time">
                  <TimePicker
                    suffixIcon={null}
                    defaultValue={dayjs(data?.start_time, "HH:mm:ss")}
                    placeholder="From"
                    onChange={(value, timeSting) =>
                      handleRolePermissionChange(
                        data?.day,
                        "start_time",
                        value,
                        timeSting ?? data?.start_time
                      )
                    }
                  />
                  <TimePicker
                    suffixIcon={null}
                    defaultValue={dayjs(data?.end_time, "HH:mm:ss")}
                    placeholder="To"
                    onChange={(value, timeSting) =>
                      handleRolePermissionChange(
                        data?.day,
                        "end_time",
                        value,
                        timeSting ?? data?.end_time
                      )
                    }
                  />
                </div>
              </div>
            ))}
            <div className="btn_grp">
              <button type="button" className="save_btn" onClick={saveTimeData}>
                Save
              </button>
              <button type="button" className="cancel_btn">
                Discard
              </button>
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
};
export default AwayMessage;
