/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import TopCards from "./features/top-cards";
import WelcomeBox from "./features/welcomeBox";
import ConversationHistory from "./features/conversationHistory";
import ChatStatus from "./features/chatStatus";

const AgentDashboard = () => {
  return (
    <>
      <Layout>
        <section className="main_area">
          <div className="d_flx align_top">
            <div className="w_49">
              <TopCards />
            </div>
            <div className="w_49">
              <WelcomeBox />
            </div>
          </div>

          <div className="d_flx align_top bottomCards">
            <div className="w_49">
              <ConversationHistory />
            </div>
            <div className="w_49">
              <ChatStatus />
            </div>
          </div>
        </section>
      </Layout >
    </>
  );
};
export default AgentDashboard;
