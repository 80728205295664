import React from "react";
const SaveBtn = ({ title, onClick, load }) => {
  return (
    <>
      <button type="button" className="save_btn" onClick={onClick}>
        {load ? <i className="fa fa-spinner fa-spin"></i> : title}
      </button>
    </>
  );
};
export default SaveBtn;
