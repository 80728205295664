import React from "react";
import { Link } from "react-router-dom";
import Editor from "./editor";

const Training = () => {
  return (
    <>
      <div className="agent_head d_flx">
        <h2>Default Flow</h2>
        <Link to="/addagent" className="add_btn">
          Test Simi
        </Link>
      </div>
      <div className="structure_area">
        <div className="bs-training">
          <div className=" d_flx">
            <h3>Business Training</h3>
            <div className="btn_grp">
              <button type="button" className="cancel_btn">
                Get Api
              </button>
              <button type="button" className="save_btn">
                Demo
              </button>
            </div>
          </div>
          <Editor />
        </div>
      </div>
    </>
  );
};
export default Training;
