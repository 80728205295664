import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const agetnApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        AgentById: builder.query({
            query: (agentId) => ({
                url: END_POINTS.AgentById + agentId + "/",
                method: 'GET',
            }),
        }),
        deleteAgent: builder.mutation({
            query: (agentId) => ({
                url: END_POINTS.deleteAgent + agentId + "/",
                method: 'DELETE',
            }),
        }),
        editAgent: builder.mutation({
            query: ({ body, id }) => ({
                url: END_POINTS.editAgent + id + "/",
                method: 'PUT',
                body,
            }),
        }),
        filterAgent: builder.mutation({
            query: (body) => ({
                url: END_POINTS.filterAgent,
                method: 'POST',
                body,
            }),
        }),
        agentAdd: builder.mutation({
            query: (body) => ({
                url: END_POINTS.addAgent,
                method: 'POST',
                body,
            }),
        }),
        companyAgnt: builder.mutation({
            query: (body) => ({
                url: END_POINTS.companyAgent,
                method: 'POST',
                body,
            }),
        }),
        pieDataById: builder.query({
            query: (id) => ({
                url: `${END_POINTS.pieDataById}${id}/`,
                method: 'GET',
            }),
        }),
        convsnHistoryById: builder.mutation({
            query: ({ body, id }) => ({
                url: `${END_POINTS.convsnHistoryById}${id}/`,
                method: 'POST',
                body,
            }),

        })
    }),
});

export const
    {
        useLazyAgentByIdQuery,
        useDeleteAgentMutation,
        useEditAgentMutation,
        useFilterAgentMutation,
        useAgentAddMutation,
        useCompanyAgntMutation,
        useLazyPieDataByIdQuery,
        useConvsnHistoryByIdMutation,
    } = agetnApi;
