import { Dropdown } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export const IntentView = () => {

  const items = [
    {
      label: (
        <ul
          role="menu"
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="button-types-dropdown"
        >
          <li class="dropdown-header">Add Button</li>
          <li>
            <a>
              <figure>
                <img src="../images/bk_arrow.svg" alt="dots" />
              </figure>
              <span class="gBXVrn">
                {" "}
                Default Button{" "}
              </span>
              <span class="bTkyvh">
                {" "}
                Suggested Replies{" "}
              </span>
            </a>
          </li>
          <li>
            <a>
            <figure>
                <img src="../images/prefix.svg" alt="dots" />
              </figure>
              <span class="gBXVrn">
                {" "}
                Link Button{" "}
              </span>
              <span class="bTkyvh">
                {" "}
                Add links to button{" "}
              </span>
            </a>
          </li>
          <li>
            <a>
            <figure>
                <img src="../images/sb_btn.svg" alt="dots" />
              </figure>
              <span class="gBXVrn">
                {" "}
                Submit Button{" "}
              </span>
              <span class="bTkyvh">
                {" "}
                Send data on button press{" "}
              </span>
            </a>
          </li>
        </ul>
      ),
      key: "0",
    },
  ];

  return (
      <div className="bot_build_page">
        <div className="bot_name_input">
          <input type="text" placeholder="Enter Intent Name" />
        </div>
        <div className="scrlnw_bx">
          <div className="stps_grp">
            <div class="SmartReplyStyles__ReplayWrapper-sc-4t1kfh-62 jnzQve">
              <div class="SmartReplyStyles__ReplaySelector-sc-4t1kfh-63 gjxNwZ">
                <h4> Step 1: User says</h4>
                <p title="Setup a welcome message for your users.">
                  Training phrases that your users might ask or say.
                </p>
              </div>
            </div>
            <div class="SmartReplyStyles__ReplayWrapper-sc-4t1kfh-62 jnzQve">
              <div class="SmartReplyStyles__ReplaySelector-sc-4t1kfh-63 gjxNwZ">
                <h4> Step 2: Bot Says</h4>
                <p title="Setup a welcome message for your users.">
                  Add bot responses to respond to what users say.
                </p>
              </div>
            </div>
          </div>
          <div className="trng_phrase">
            <h3>Configure User's Message</h3>
            <p>
              Kompose uses AI capabilities to match the training phrase to the
              user message. The more phrases you add, the better the bot will
              get at answering user’s questions.
            </p>
            <p>
              Add training phrases, and <Link to=""> entities</Link> (start by
              typing <b>'$'</b> in below fields)
            </p>
            <div className="add_trg_box">
              <button type="button">+ Add Training Phrase</button>
              <input type="text" placeholder="Enter the user's message"></input>
            </div>
          </div>
        </div>
        <div className="btns_all">
            <button type="button" className="dlt_btn disable">Delete</button>
            <button type="button" className="dlt_btn disable">Discard</button>
            <button type="button" className="save_btn disable">Save</button>
        </div>

        <div className="edt_msg">
          <div className="scrl_bx">
            <h3>Configure bot's reply</h3>
            <div className="py_ld">
              <p>
                Add text, buttons, images, custom payload messages
                <Link to=""> personalize messages</Link> and{" "}
                <Link to="">dynamic message</Link>
              </p>
            </div>
            <div className="bot_mssg">
              <figure>
                <img src="../images/dots.svg" alt="dots" />
              </figure>
              <div className="bt_txtarea">
                <label>Bot's Message</label>
                <textarea placeholder="Create a custom welcome message to greet your users" />
                <figure>
                  <img src="../images/usr.svg" alt="dots" />
                </figure>
              </div>
              <figure>
                <img src="../images/dlt.svg" alt="dots" />
              </figure>
            </div>
            <div className="bot_mssg">
            <figure>
              <img src="../images/dots.svg" alt="dots" />
            </figure>
            <div className="bt_txtarea">
              <label>Buttons</label>
              <div className="btn_add">
                <figure>
                  <img src="../images/dots.svg" alt="dots" />
                </figure>
                <div className="btn_inpt">
                  <input type="text"></input>
                  <figure>
                    <img src="../images/prefix.svg" alt="dots" />
                  </figure>
                </div>
                <Dropdown
                  menu={{ items }}
                  trigger={["click"]}
                  placement="topRight"
                >
                  <button type="button" className="buttons_add">
                    +
                  </button>
                </Dropdown>
              </div>
            </div>
            <figure>
              <img src="../images/dlt.svg" alt="dots" />
            </figure>
          </div>
            <div className="bot_mssg">
              <figure>
                <img src="../images/dots.svg" alt="dots" />
              </figure>
              <div className="bt_txtarea">
                <div className="upld_drag_img">
                  <span>Drag & drop / Click here to upload image</span>
                  <input type="file" />
                </div>
                <h3 className="text_center">OR</h3>
                <label>Image URL</label>
                <textarea placeholder="Please enter the image URL here" />
                <label>Caption (Optional)</label>
                <textarea placeholder="Please enter the image caption here" />
              </div>
              <figure>
                <img src="../images/dlt.svg" alt="dots" />
              </figure>
            </div>
            <div className="btn_grps">
              <button type="button">+ Text</button>
              <button type="button">+ Buttons</button>
              <button type="button">
                + More{" "}
                <img
                  className="drp_img"
                  src="../images/drp.svg"
                  alt="img"
                ></img>
                <div className="drp_dwn">
                  <h3>RICH MESSAGES</h3>
                  <ul>
                    <li>
                      <img src="../images/img.svg" alt="img" /> Image{" "}
                      <span>Image with caption</span>
                    </li>
                  </ul>
                </div>
              </button>
            </div>
            <div className="dnc_msg">
              <h5>Dynamic Message</h5>
              <div className="csat_toggle d_flx">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="lgt_cnt">
              <p>
                Dynamic messaging helps you to fetch your custom data and send
                it to the end users. Below are the two options to send a dynamic
                message.
              </p>
              <div className="inln_cd">
                <div className="input_radio">
                  <input
                    type="radio"
                    id="html1"
                    name="fav_language"
                    className="input-radio"
                  />
                  <label htmlFor="html1">Inline Code</label>
                </div>
              </div>
              <p>
                Write custom code to retrieve or send data to your database or
                API <Link to="">Know more</Link>
              </p>
              <div className="slc_bx"></div>
              <div className="inln_cd">
                <div className="input_radio">
                  <input
                    type="radio"
                    id="html2"
                    name="fav_language"
                    className="input-radio"
                  />
                  <label htmlFor="html2">Webhook</label>
                </div>
              </div>
              <p>
                Connect your Webhook or API to retrieve or send data to your
                server or database <Link to="">Know more</Link>
              </p>
              <div className="slc_bx"></div>
            </div>
          </div>
          <div className="sv_cncl_btn">
            <button type="button" className="cncl_btn">
              Delete
            </button>
            <button type="button" className="cncl_btn">
              Discard
            </button>
            <button type="button" className="sv_btn">
              Save
            </button>
          </div>
        </div>
      </div>
  );
};
