/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";

const { confirm } = Modal;

const ConfirmEndChat = (handleEndChat, condition) => {
  confirm({
    title: `Are you sure want to ${
      condition === "end" ? "end chat" : "mark as unresolved"
    }`,
    icon: <ExclamationCircleFilled />,
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      console.log("OK");
      handleEndChat();
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};
export default ConfirmEndChat;
