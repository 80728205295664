const GoogleButton = ({name, onClick}) => {
  return (
    <button type="button" className="google_btn mr_auto_50" onClick={onClick}>
      <figure>
        <img src="/images/google.svg" alt="customer"></img>
      </figure>
      <span>{name} with google</span>
    </button>
  );
};
export default GoogleButton;
