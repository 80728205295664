import { Empty } from "antd";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useBotHandleConvsnMutation } from "../services/createbot";
import { successToast } from "../helpers";
import { useLazyGetDomainByIdQuery } from "../services/managebot";

const EditDomain = ({ open, setOpen, id }) => {
  const [handleBotConvrsn] = useBotHandleConvsnMutation();
  const [getDomain] = useLazyGetDomainByIdQuery();
  const [domain, setDomain] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDomain(id);
        console.log("response:pooopopoo ", response);
        setDomain(response?.data?.data?.domain_setup);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    if (id) {
      fetchData();
    }
  }, [getDomain, id]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
    },
  };

  function closeModal() {
    setOpen(false);
  }

  const handleBotConversation = async () => {
    try {
      let body = {
        domain_setup: domain,
      };
      const response = await handleBotConvrsn({ body, id });
      console.log("response: ", response);
      if (response?.data?.code === 200) {
        successToast(response?.data?.message);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setOpen(false);
    }
  };

  const handleInput = (e, index) => {
    const newDomain = [...domain];
    newDomain[index] = e.target.value;
    setDomain(newDomain);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleBotConversation();
    }
  };

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={open}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="edit_domain">
        <div className="modal_header">
          <h2>Edit Domain</h2>
          <figure onClick={() => setOpen(false)}>
            <img src="./images/close.svg" alt="cross" />
          </figure>
        </div>
        <div className="Edit_domain_cnt">
          {domain?.length ? (
            domain?.map((data, index) => (
              <div className="input_box pd_input" key={index}>
                <input
                  type="text"
                  placeholder={data}
                  onChange={(e) => handleInput(e, index)}
                  onKeyDown={handleKeyDown}
                />
              </div>
            ))
          ) : (
            <Empty />
          )}
        </div>
        {domain?.length && (
          <button
            type="button"
            className="save_btn"
            onClick={handleBotConversation}
          >
            Update
          </button>
        )}
      </div>
    </Modal>
  );
};

export default EditDomain;
