import Email from "../../../components/email";
import FormHead from "../../../components/formhead";
import LoginButton from "../../../components/loginbutton";
import VerifyModal from "../../../components/modal";
import { useUserFgtPassMutation } from "../../../services/api";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { errorToast, successToast } from "../../../helpers/showToast";
import { Spin } from "antd";
import { isEmail } from "../../../utils/validations";

const FormForgot = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [forgetPassword] = useUserFgtPassMutation();
  const [require, setRequire] = useState(false);
  const [load, setLoad] = useState(false);

  const forgetPass = async () => {
    if (!email || !isEmail(email)) {
      setRequire(true);
      return;
    }
    let body = {
      email: email,
    };
    let response;
    try {
      setLoad(true);
      response = await forgetPassword(body);
      if (response?.data?.code === 200) {
        successToast(response?.data?.message);
        setOpen(true);
      } else {
        errorToast(response?.error?.data.message);
      }
    } catch (error) {
      console.log("enter correct credentials");
      errorToast(response?.error?.data.message);
    } finally {
      setLoad(false);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      forgetPass();
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <Spin spinning={load}>
        <section className="login_page">
          <div className="login_bx">
            <div className="lg_frm">
              <Link to="/login">
                <i className="fa-solid fa-chevron-left back_frgt"></i>
              </Link>
              <FormHead title="Reset your password" />
              <Email
                value={email}
                onChange={handleEmail}
                require={require}
                login={true}
                onKeyDown={handleKeyPress}
              />
              <LoginButton name="Reset Password" click={forgetPass} />
            </div>
          </div>
        </section>
        <VerifyModal
          setOpen={setOpen}
          open={open}
          email={email}
          data={"Reset_Password"}
        />
      </Spin>
    </>
  );
};

export default FormForgot;
