import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const pushNotifn = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    pushNotification: builder.mutation({
      query: (body) => ({
        url: END_POINTS.pushNotification,
        method: "POST",
        body,
      }),
    }),
    addServerKey: builder.mutation({
      query: (body) => ({
        url: END_POINTS.addServerKey,
        method: "PUT",
        body,
      }),
    }),
    getServerKey: builder.query({
      query: () => ({
        url: END_POINTS.getServerKey,
        method: "GET",
      }),
    }),
  }),
});

export const {
  usePushNotificationMutation,
  useAddServerKeyMutation,
  useLazyGetServerKeyQuery,
} = pushNotifn;
