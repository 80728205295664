import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const createBotApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addBotAvtr: builder.mutation({
      query: (body) => ({
        url: END_POINTS.addAvatar,
        method: "POST",
        body,
      }),
    }),
    AllAvatar: builder.query({
      query: () => ({
        url: END_POINTS.getAllAvatar,
        method: "GET",
      }),
    }),
    botTmplt: builder.mutation({
      query: (body) => ({
        url: END_POINTS.getBotTemplate,
        method: "POST",
        body,
      }),
    }),
    crteBot: builder.mutation({
      query: (body) => ({
        url: END_POINTS.createBot,
        method: "POST",
        body,
      }),
    }),
    botHandleConvsn: builder.mutation({
      query: ({ body, id }) => ({
        url: `${END_POINTS.botHandleConvsn}${id}/`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useLazyAllAvatarQuery,
  useAddBotAvtrMutation,
  useBotTmpltMutation,
  useCrteBotMutation,
  useBotHandleConvsnMutation,
} = createBotApi;
