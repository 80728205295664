import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const settingApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    themeById: builder.query({
      query: (id) => ({
        url: END_POINTS.themeById + id + "/",
        method: "GET",
      }),
    }),
    getAgntTiming: builder.query({
      query: () => ({
        url: END_POINTS.getAgntTiming,
        method: "GET",
      }),
    }),
    editAgntTiming: builder.mutation({
      query: (body) => ({
        url: END_POINTS.agentTiming,
        method: "PUT",
        body,
      }),
    }),
    buySbsrptn: builder.mutation({
      query: (body) => ({
        url: END_POINTS.buySubscription,
        method: "POST",
        body,
      }),
    }),
    getSubscription: builder.mutation({
      query: (body) => ({
        url: END_POINTS.subscriptionList,
        method: "POST",
        body,
      }),
    }),
    editTheme: builder.mutation({
      query: ({ body, id }) => ({
        url: `${END_POINTS.editThemeById}${id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateCsat: builder.mutation({
      query: ({ body, id }) => ({
        url: `${END_POINTS.updateCsat}${id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateFallback: builder.mutation({
      query: ({ body, id }) => ({
        url: `${END_POINTS.updateFallback}${id}/`,
        method: "PUT",
        body,
      }),
    }),
    SendAwaymessage: builder.mutation({
      query: ({ body, id }) => ({
        url: `${END_POINTS.SendAwaymessage}${id}/`,
        method: "PUT",
        body,
      }),
    }),
    anonymousUser: builder.mutation({
      query: ({ body, id }) => ({
        url: `${END_POINTS.anonymousUser}${id}/`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useEditThemeMutation,
  useEditAgntTimingMutation,
  useBuySbsrptnMutation,
  useLazyThemeByIdQuery,
  useLazyGetAgntTimingQuery,
  useGetSubscriptionMutation,
  useUpdateCsatMutation,
  useUpdateFallbackMutation,
  useSendAwaymessageMutation,
  useAnonymousUserMutation,
} = settingApi;
