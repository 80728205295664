import React, { useEffect } from "react";
import Layout from "../../layout";
import Hero from "./features/hero";
import About from "./features/about";
import OurBot from "./features/ourbot";
import UserExperience from "./features/user_experience";
import Immersive from "./features/immersive";
import Video from "./features/video";
import Seamless from "./features/seameless";
import Customer from "./features/customer";
import Aos from "aos";
import "aos/dist/aos.css";

function HomePage() {
  useEffect(function () {
    Aos.init({ duration: 1000, disable: 'mobile' });
  }, []);
  return (
    <Layout className="home_page">
      <Hero />
      <About />
      <OurBot />
      <UserExperience />
      <Immersive />
      <Video />
      <Seamless />
      <Customer />
    </Layout>
  );
}

export default HomePage;
